
import { defineComponent } from 'vue'
import moment from 'moment'
import { Modal } from 'bootstrap'
import { ErrorMessage, Field, Form } from 'vee-validate'
import DocumentType from '../../types/DocumentType'
import ResponseType from '../../types/Response'

const __default__ = defineComponent({
  name: 'MultiStepFormApplyModal',
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      form: {
        gender: undefined,
        firstname: '',
        lastname: '',
        username: '',
        phoneNumber: '',
        profilePicture: undefined,
        cv: undefined,
        cvId: '',
        motivationLetterId: '',
        motivationLetter: undefined,
        latestCvId: 0,
        latestMotivationLetterId: 0,
        wid: undefined
      },
      latestCv: {
        id: 0,
        name: '',
        uploadedAt: '',
        path: '',
        documentType: '',
      } as DocumentType,
      latestMotivationLetter: {
        id: 0,
        name: '',
        uploadedAt: '',
        path: '',
        documentType: '',
      } as DocumentType,
      error: false,
      errorMsg: '',
      successMsg:'',
      consent: false,
      applyValidForm: true,
      displayCvSelect: false,
      displayMotivationLetterSelect: false,
      modalInstance: Modal,
      currentStep: 1,
      totalSteps: 1, // TO DO : Change total step to add step in apply tunnel
      genderCodeItems: [
        { text: this.$t('modal.apply.form.genderCode.male'), value: 0 },
        { text: this.$t('modal.apply.form.genderCode.female'), value: 1 },
      ],
      genderCodeRule: [
        (v) =>
          typeof v == 'number' ||
          this.$t('modal.apply.form.genderCode.required'),
      ],
      applicationCategoriesRule: [
        (v) => !!v ||
          this.$t('modal.apply.form.applicationCategories.required'),
      ],
      firstnameRule: [
        (v) => !!v || this.$t('modal.apply.form.firstname.required'),
        (v) =>
          (v && v.length <= 20) ||
          this.$tc('modal.apply.form.firstname.max', 20),
        (v) =>
          (v && v.length >= 3) || this.$tc('modal.apply.form.firstname.min', 3),
      ],
      lastnameRule: [
        (v) => !!v || this.$t('modal.apply.form.lastname.required'),
        (v) =>
          (v && v.length <= 20) ||
          this.$tc('modal.apply.form.lastname.max', 20),
        (v) =>
          (v && v.length >= 3) || this.$tc('modal.apply.form.lastname.min', 3),
      ],
      usernameRule: [
        (v) => !!v || this.$t('modal.apply.form.email.required'),
        (v) => /.+@.+\..+/.test(v) || this.$t('modal.apply.form.email.invalid'),
        (v) =>
          (v && v.length <= 50) || this.$tc('modal.apply.form.email.max', 50),
      ],
      cvInputRule:  [
        (v) => !!v || this.$t('modal.apply.form.CV.required'),
      ],
      cvSelectRule: [
        (v) =>  v.title !== '' || this.$t('modal.apply.form.CV.required')
      ],
      isCvInput: false,
      isMotivationLetterInput: false,
      cvExist: { title: '', value: '' },
      motivationLetterExist: { title: '', value: '' },
      documents: [] as DocumentType[],
      applicationCategories: [],
      primaryColor: this.$store.state.client.design.primaryColor,
      secondaryColor: this.$store.state.client.design.secondaryColor,
      loading: false,
    }
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: Number,
    },
  },
  mounted() {
    const form = this.$refs.applyForm as any
    form.reset()
    if (this.loggedIn) {
      this.setUserIntoForm()
    }
  },
  watch: {
    showModal(newVal, oldVal) {
      if (newVal === true) {
        this.modalActive()
        if (this.jobId == 0) {
          this.getApplicationCategories()
        }
        if (this.loggedIn) {
          this.getUserDocument()
        }
      } else {
        this.resetModal()
      }
    },
    motivationLetterDocuments(newMotivationLetter, oldMotivationLetter) {
      if (newMotivationLetter.length > 0) {
        this.displayMotivationLetterSelect = true
      }
    },
    cvDocuments(newCv, oldCv) {
      if (newCv.length > 0) {
        this.displayCvSelect = true
      }
    },
  },

  computed: {
    isFirstStep() {
      return this.currentStep === 1
    },
    isLastStep() {
      return this.currentStep === this.totalSteps
    },
    loggedIn() {
      return this.$store.state.auth.loggedIn
    },
    cvDocuments: function (): Array<object> {
      return this.documents
        .filter(function (doc: any) {
          return doc.documentType === 'CV'
        })
        .map((doc) => ({ title: doc.name, value: doc.id }))
    },
    motivationLetterDocuments: function (): Array<object> {
      return this.documents
        .filter(function (doc: any) {
          return doc.documentType === 'ML'
        })
        .map((doc) => ({ title: doc.name, value: doc.id }))
    },
  },
  methods: {
    selectCV(event) {
      this.form.cv = event.target.files[0]
    },
    selectML(event) {
      this.form.motivationLetter = event.target.files[0]
    },
    selectProfilePicture(event) {
      this.form.profilePicture = event.target.files[0]
    },
    resetModal() {
      const form = this.$refs.applyForm as any
      form.reset()
      form.resetValidation()
      this.currentStep = 1
      this.error = false
      this.errorMsg = ''
    },
    modalActive: function () {
      this.modalInstance = new Modal(document.getElementById('myModal'), {
        target: '#my-modal',
        backdrop: 'static',
      })
      this.modalInstance.show()
    },
    hideModal: function () {
      this.modalInstance.hide()
      this.$emit('closeModal')
    },
    nextStep() {
      this.currentStep++
    },
    prevStep() {
      this.currentStep--
    },
    async validate() {
      if (this.applyValidForm) {
        this.submitForm()
      }
    },
    submitForm() {
      this.loading = true;
      if (this.cvExist.value !== '') {
        this.form.cvId = this.cvExist.value
      }
      if (this.motivationLetterExist.value !== '') {
        this.form.motivationLetterId = this.motivationLetterExist.value
      }

      this.$store.dispatch('apply/applyJob', {
          jobId: this.jobId,
          form: this.form,
      }).then(
        (data: ResponseType) => {
          this.loading = false
          this.currentStep = 0 // End of tunnel
          this.successMsg = data.message
        },
        (error: ResponseType) => {
          this.loading = false
        }
      )
    },
    getApplicationCategories() {
      this.$store.dispatch('apply/getApplicationCategories', this.$i18n.locale).then((response: any) => {
        this.applicationCategories = response;
      })
    },
    getUserDocument() {
      this.$store
        .dispatch('user/getDocuments')
        .then((res) => (this.documents = res.data.documents))
      if (this.documents) {
        const cvs = this.documents.filter(
          (doc: any) => doc.documentType === 'CV'
        )

        for (let i = 0; i < cvs.length; i++) {
          if (
            this.latestCv.uploadedAt == '' ||
            moment(cvs[i]['uploadedAt']).isAfter(this.latestCv.uploadedAt)
          ) {
            this.latestCv = cvs[i]
            this.form.latestCvId = this.latestCv.id
          }
        }

        const motivationLetters = this.documents.filter(
          (doc: any) => doc.documentType === 'ML'
        )

        for (let i = 0; i < motivationLetters.length; i++) {
          if (
            this.latestMotivationLetter.uploadedAt == '' ||
            moment(motivationLetters[i]['uploadedAt']).isAfter(
              this.latestMotivationLetter.uploadedAt
            )
          ) {
            this.latestMotivationLetter = motivationLetters[i]
            this.form.latestMotivationLetterId = this.latestMotivationLetter.id
          }
        }
      }
    },
    setUserIntoForm() {
      const user = this.$store.state.user
      if (user !== null) {
        this.form.gender = user.profile.genderCode
        this.form.firstname = user.profile.firstname
        this.form.lastname = user.profile.lastname
        this.form.username = user.username
        this.form.phoneNumber = user.profile.phoneNumber
      }
    },
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "00c73190": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__