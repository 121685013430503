
import { defineComponent } from 'vue'
import ValidationForm from '../../components/Validation/ValidationForm.vue'

export default defineComponent({
  name: 'ValidationView',
  components: {
    ValidationForm,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
})
