import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "text-center mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "error" }, "404", -1)),
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t("page.errors.notFound.title")), 1),
    _createElementVNode("p", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t("page.errors.notFound.notFoundText_1")), 1),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("page.errors.notFound.notFoundText_2")), 1)
    ])
  ]))
}