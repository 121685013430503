import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "footer-container d-flex justify-space-between" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createBlock(_component_v_footer, { border: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex align-center" }, [
          _createElementVNode("p", { class: "mr-2 mb-0" }, "Powered by"),
          _createElementVNode("img", { src: "https://beetween.com/wp-content/uploads/2023/08/logo-beetween.svg" })
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            class: "link",
            to: { path: '/legal-mentions' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('page.jobs.legalMentions')), 1)
            ]),
            _: 1
          }),
          _cache[0] || (_cache[0] = _createTextVNode(" - ")),
          _createVNode(_component_router_link, {
            class: "link",
            to: { path: '/personal-data' }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('page.jobs.personalData')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}