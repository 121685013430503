
import { truncate, cleanText, formatJobDate, camelCase } from '@/utils/string'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
const __default__ = defineComponent({
  name: 'JobsList',
  props: ['jobs', 'jobsFound'],
  data() {
    return {
      numPage: 0,
      numVisiblePage: 5,
      page: 1,
    }
  },
  setup() {
    return {truncate, cleanText, formatJobDate, camelCase}
  },
  computed: {
    ...mapState(['client']),
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2c37deaa": (_ctx.client.design.primaryColor),
  "537f2790": (_ctx.client.design.secondaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__