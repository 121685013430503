import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "banner-container" }
const _hoisted_2 = { class: "search-return" }
const _hoisted_3 = { class: "main-input" }
const _hoisted_4 = { class: "second-input" }
const _hoisted_5 = { class: "d-flex flex-column" }
const _hoisted_6 = { class: "mobile-button" }
const _hoisted_7 = { class: "content" }
const _hoisted_8 = { class: "jobs-map-container" }
const _hoisted_9 = { class: "right pl-6 pr-6" }
const _hoisted_10 = { class: "d-flex justify-space-between offers-liste-header mt-2" }
const _hoisted_11 = { class: "flex-row" }
const _hoisted_12 = { class: "mb-2 ml-2" }
const _hoisted_13 = { class: "flex-row" }
const _hoisted_14 = { class: "filters d-none d-md-flex" }
const _hoisted_15 = { class: "d-flex justify-space-between align-center w-100" }
const _hoisted_16 = {
  key: 0,
  class: "d-none d-md-flex pb-4 w-50"
}
const _hoisted_17 = { class: "d-none d-md-flex flex-row banner-action align-items-center" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  class: "d-none d-md-flex",
  style: {"padding-left":"8px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_VDivider = _resolveComponent("VDivider")!
  const _component_VListSubheader = _resolveComponent("VListSubheader")!
  const _component_VCheckboxBtn = _resolveComponent("VCheckboxBtn")!
  const _component_VListItem = _resolveComponent("VListItem")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_Map = _resolveComponent("Map")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_JobsList = _resolveComponent("JobsList")!
  const _component_TarteAuCitron = _resolveComponent("TarteAuCitron")!
  const _component_MultiStepFormApplyModal = _resolveComponent("MultiStepFormApplyModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      class: _normalizeClass(["banner banner-mobile banner-mobile-active d-flex d-sm-flex d-md-none", { active: _ctx.focusedClass }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_btn, {
            class: "",
            onClick: _ctx.leaveSearch
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "fa-arrow-left" })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _cache[20] || (_cache[20] = _createElementVNode("h2", { class: "d-flex align-center mb-0" }, "Ma recherche", -1))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_text_field, {
            "prepend-inner-icon": "mdi-magnify",
            modelValue: _ctx.keywords,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keywords) = $event)),
            placeholder: _ctx.$t('page.jobs.input.keywords.placeholder'),
            onFocus: _ctx.focus,
            onBlur: _ctx.blur,
            variant: "outlined"
          }, null, 8, ["modelValue", "placeholder", "onFocus", "onBlur"]),
          _createVNode(_component_v_autocomplete, {
            class: "input-location mt-5",
            "prepend-inner-icon": "mdi-map-marker-outline",
            modelValue: _ctx.locations,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.locations) = $event)),
            disabled: _ctx.isUpdating,
            items: _ctx.facets.location,
            label: _ctx.$t('page.jobs.input.location.placeholder'),
            "custom-filter": _ctx.locationFilter,
            multiple: "",
            height: "10",
            variant: "outlined"
          }, {
            item: _withCtx(({ props, item }) => [
              (typeof item.raw === 'object' && 'divider' in item.raw)
                ? (_openBlock(), _createBlock(_component_VDivider, { key: 0 }))
                : (typeof item.raw === 'object' && 'header' in item.raw)
                  ? (_openBlock(), _createBlock(_component_VListSubheader, {
                      key: 1,
                      title: item.raw.header
                    }, null, 8, ["title"]))
                  : (_openBlock(), _createBlock(_component_VListItem, _mergeProps({ key: 2 }, props, {
                      label: item.title,
                      value: item.value
                    }), {
                      prepend: _withCtx((status) => [
                        (_openBlock(), _createBlock(_component_VCheckboxBtn, {
                          key: item.value,
                          modelValue: status.isSelected,
                          ripple: false,
                          tabindex: "-1"
                        }, null, 8, ["modelValue"]))
                      ]),
                      _: 2
                    }, 1040, ["label", "value"]))
            ]),
            selection: _withCtx(({ item, index }) => [
              (index === 0)
                ? (_openBlock(), _createBlock(_component_v_chip, {
                    key: 0,
                    closable: "",
                    color: _ctx.client.design.primaryColor,
                    "onClick:close": _cache[1] || (_cache[1] = ($event: any) => (_ctx.locations = []))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.locations.length) + " " + _toDisplayString(_ctx.$tc('page.jobs.chip.label', _ctx.locations.length > 1 ? 2 : 1)), 1)
                    ]),
                    _: 1
                  }, 8, ["color"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "disabled", "items", "label", "custom-filter"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.contractTypes,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contractTypes) = $event)),
              items: _ctx.facets.contractType,
              placeholder: _ctx.$t('page.jobs.input.contractType.placeholder'),
              class: "input-header mb-5",
              "prepend-inner-icon": "mdi-file-document-edit",
              multiple: "",
              variant: "outlined"
            }, {
              selection: _withCtx(({ item, index }) => [
                (index === 0)
                  ? (_openBlock(), _createBlock(_component_v_chip, {
                      key: 0,
                      closable: "",
                      color: _ctx.client.design.primaryColor,
                      "onClick:close": _cache[3] || (_cache[3] = ($event: any) => (_ctx.contractTypes = []))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.contractTypes.length) + " " + _toDisplayString(_ctx.$tc(
                'page.jobs.chip.label',
                _ctx.contractTypes.length > 1 ? 2 : 1
                )), 1)
                      ]),
                      _: 1
                    }, 8, ["color"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "items", "placeholder"]),
            (_ctx.facets.categories && _ctx.facets.categories.length > 0)
              ? (_openBlock(), _createBlock(_component_v_autocomplete, {
                  key: 0,
                  modelValue: _ctx.categories,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.categories) = $event)),
                  items: _ctx.facets.categories,
                  placeholder: _ctx.$t('page.jobs.input.categories.placeholder'),
                  class: "input-header mb-5",
                  "prepend-inner-icon": "mdi-file-document-edit",
                  multiple: "",
                  variant: "outlined"
                }, {
                  selection: _withCtx(({ item, index }) => [
                    (index === 0)
                      ? (_openBlock(), _createBlock(_component_v_chip, {
                          key: 0,
                          closable: "",
                          color: _ctx.client.design.primaryColor,
                          "onClick:close": _cache[5] || (_cache[5] = ($event: any) => (_ctx.categories = []))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.categories.length) + " " + _toDisplayString(_ctx.$tc(
                'page.jobs.chip.label',
                _ctx.categories.length > 1 ? 2 : 1
                )), 1)
                          ]),
                          _: 1
                        }, 8, ["color"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue", "items", "placeholder"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_v_btn, {
            small: "",
            class: "btn btn-primary col btn-banner-search",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getJobs(true)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "fa-search" })
            ]),
            _: 1
          })
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Map, {
          class: "d-none d-md-flex",
          jobs: _ctx.jobs,
          onUpdateMap: _ctx.setCoordinates,
          currentJobId: _ctx.currentJobId,
          fitBounds: _ctx.isBoundFit,
          onUpdateFitBound: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isBoundFit = false))
        }, null, 8, ["jobs", "onUpdateMap", "currentJobId", "fitBounds"]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h1", _hoisted_12, _toDisplayString(_ctx.$t('page.jobs.title', { n: _ctx.jobs.length })), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_v_btn, {
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.dataModal = true)),
                class: "btn-primary-inverse"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-paper-plane",
                    class: "mr-2"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("page.jobs.buttons.spontaneous-application")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        dense: "",
                        class: "mr-3 map-form",
                        "prepend-inner-icon": "mdi-magnify",
                        modelValue: _ctx.keywords,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.keywords) = $event)),
                        label: _ctx.$t('page.jobs.input.keywords.placeholder'),
                        variant: "outlined"
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_v_autocomplete, {
                        class: "mr-0 map-form",
                        "prepend-inner-icon": "mdi-map-marker-outline",
                        modelValue: _ctx.locations,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.locations) = $event)),
                        disabled: _ctx.isUpdating,
                        items: _ctx.facets.location,
                        "custom-filter": _ctx.locationFilter,
                        label: _ctx.$t('page.jobs.input.location.placeholder'),
                        multiple: "",
                        height: "10",
                        variant: "outlined"
                      }, {
                        item: _withCtx(({ props, item }) => [
                          (typeof item.raw === 'object' && 'divider' in item.raw)
                            ? (_openBlock(), _createBlock(_component_VDivider, { key: 0 }))
                            : (typeof item.raw === 'object' && 'header' in item.raw)
                              ? (_openBlock(), _createBlock(_component_VListSubheader, {
                                  key: 1,
                                  title: item.raw.header
                                }, null, 8, ["title"]))
                              : (_openBlock(), _createBlock(_component_VListItem, _mergeProps({ key: 2 }, props, {
                                  label: item.title,
                                  value: item.value
                                }), {
                                  prepend: _withCtx((status) => [
                                    (_openBlock(), _createBlock(_component_VCheckboxBtn, {
                                      key: item.value,
                                      modelValue: status.isSelected,
                                      ripple: false,
                                      tabindex: "-1"
                                    }, null, 8, ["modelValue"]))
                                  ]),
                                  _: 2
                                }, 1040, ["label", "value"]))
                        ]),
                        selection: _withCtx(({ item, index }) => [
                          (index === 0)
                            ? (_openBlock(), _createBlock(_component_v_chip, {
                                key: 0,
                                closable: "",
                                color: _ctx.client.design.primaryColor,
                                "onClick:close": _cache[11] || (_cache[11] = ($event: any) => (_ctx.locations = []))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.locations.length) + " " + _toDisplayString(_ctx.$tc('page.jobs.chip.label', _ctx.locations.length > 1 ? 2 : 1)), 1)
                                ]),
                                _: 1
                              }, 8, ["color"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue", "disabled", "items", "custom-filter", "label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      (_ctx.moreCriteria)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createVNode(_component_v_autocomplete, {
                              class: "input-header mr-3 map-form",
                              modelValue: _ctx.contractTypes,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.contractTypes) = $event)),
                              items: _ctx.facets.contractType,
                              label: _ctx.$t('page.jobs.input.contractType.placeholder'),
                              "prepend-inner-icon": "mdi-file-document-edit-outline",
                              multiple: "",
                              variant: "outlined"
                            }, {
                              selection: _withCtx(({ item, index }) => [
                                (index === 0)
                                  ? (_openBlock(), _createBlock(_component_v_chip, {
                                      key: 0,
                                      closable: "",
                                      color: _ctx.client.design.primaryColor,
                                      "onClick:close": _cache[13] || (_cache[13] = ($event: any) => (_ctx.contractTypes = []))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.contractTypes.length) + " " + _toDisplayString(_ctx.$tc('page.jobs.chip.label', _ctx.contractTypes.length > 1 ? 2 : 1)), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["color"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["modelValue", "items", "label"]),
                            (_ctx.facets.categories.length > 0)
                              ? (_openBlock(), _createBlock(_component_v_autocomplete, {
                                  key: 0,
                                  class: "input-header mr-3 map-form",
                                  modelValue: _ctx.categories,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.categories) = $event)),
                                  items: _ctx.facets.categories,
                                  label: _ctx.$t('page.jobs.input.categories.placeholder'),
                                  "prepend-inner-icon": "mdi-file-document-edit-outline",
                                  multiple: "",
                                  variant: "outlined"
                                }, {
                                  selection: _withCtx(({ item, index }) => [
                                    (index === 0)
                                      ? (_openBlock(), _createBlock(_component_v_chip, {
                                          key: 0,
                                          closable: "",
                                          color: _ctx.client.design.primaryColor,
                                          "onClick:close": _cache[15] || (_cache[15] = ($event: any) => (_ctx.categories = []))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.categories.length) + " " + _toDisplayString(_ctx.$tc('page.jobs.chip.label', _ctx.categories.length > 1 ? 2 : 1)), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["color"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "items", "label"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, { class: "d-flex justify-content-center action" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_v_btn, {
                          class: "btn btn-secondary btn-filter",
                          "prepend-inner-icon": "mdi-briefcase",
                          onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.moreCriteria = !_ctx.moreCriteria))
                        }, {
                          default: _withCtx(() => [
                            _cache[21] || (_cache[21] = _createElementVNode("span", { class: "mdi mdi-filter-outline" }, null, -1)),
                            (_ctx.moreCriteria)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.$t("page.jobs.buttons.less-criteria")), 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$t("page.jobs.buttons.more-criteria")), 1))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          class: "btn btn-clear btn-secondary",
                          onClick: _ctx.clearAll
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-rotate-right",
                              "aria-hidden": "true",
                              class: "mr-1"
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("page.jobs.buttons.reset")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_v_btn, {
                          small: "",
                          class: "btn btn-primary col btn-banner-search mr-2 ml-2",
                          onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.getJobs(true)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-search",
                              class: "mr-1"
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("page.jobsMap.buttons.search")), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _createVNode(_component_JobsList, {
            jobs: _ctx.jobs,
            onSetCurrentJobId: _ctx.setCurrentJobId,
            isMap: true
          }, null, 8, ["jobs", "onSetCurrentJobId"])
        ])
      ])
    ]),
    _createVNode(_component_TarteAuCitron),
    _createVNode(_component_MultiStepFormApplyModal, {
      onCloseModal: _cache[19] || (_cache[19] = ($event: any) => (_ctx.dataModal = false)),
      "show-modal": _ctx.dataModal,
      "job-id": 0
    }, null, 8, ["show-modal"])
  ], 64))
}