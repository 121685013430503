
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShareButton',
  props: {
    link: String,
    icon: String,
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    }
  }
})
