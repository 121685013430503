
import { defineComponent } from 'vue'
import UpdatePasswordForm from '@/components/Account/UpdatePasswordForm.vue'
import UpdateEmailForm from '@/components/Account/UpdateEmailForm.vue'
import DeleteMyAccount from '@/components/Account/DeleteMyAccount.vue'

const __default__ = defineComponent({
  name: 'Security',
  components: {
    UpdatePasswordForm,
    UpdateEmailForm,
    DeleteMyAccount
  },
  data() {
    return {
      showUpdatePasswordForm: false,
      showUpdateEmailForm: false,
      showDeleteMyAccount: false
    }
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    username() {
      return this.$store.state.user.username
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "e7096560": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__