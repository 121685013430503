import api from './api'
import TokenService from '@/services/token.service'

class UserService {
  getUserProfile() {
    return api.get('/account')
  }
  updateProfile(profile: object) {
    return api.put('/account/update/profile', profile).then((response) => {
      return response.data
    })
  }
  updateUsername(data: object) {
    return api.post('/account/update/username', data).then((response) => {
      TokenService.setToken(response.data.token)
      TokenService.setRefreshToken(response.data.refreshToken)
      return response.data.meResponse
    })
  }
  updatePassword(data: object) {
    return api.post('/account/update/password', data).then((response) => {
      return response.data
    })
  }
  deleteUserAccount() {
    return api.delete('/account/delete').then((response) => {
      return response.data
    })
  }
  uploadUserCV(files: Blob) {
    return api
      .post('/files/upload/cv/', files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data
      })
  }
  uploadUserML(files: Blob) {
    return api
      .post('/files/upload/ml/', files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data
      })
  }
  uploadProfilePicture(files: Blob) {
    return api
      .post('/files/upload/pp/', files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data
      })
  }
  getFile(filename: string) {
    return api
      .get(`/files/${filename}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return response.data
      })
  }

  generatePresignedUrl(filename: string) {
    return api.get(`/files/presigned-url/${filename}`).then((response) => {
        return response.data
    })
  }

  getUserFiles() {
    return api.get('files/user/').then((response) => {
      return response
    })
  }
  getProfilePicture() {
    return api
      .get('files/profile-picture/', {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return response
      })
  }
  deleteFile(filename: string) {
    return api.delete(`/files/${filename}`).then((response) => {
      return response.data
    })
  }
  getDocuments() {
    return api.get('/files/documents/').then((response) => {
      return response
    })
  }
  getUser() {
    return api.get('account/me').then((response) => {
      return response.data
    })
  }
  forgotPassword(email: string) {
    return api
      .post('/auth/password/reset-request', {
        email,
      })
      .then((response) => {
        return response.data
      })
  }
  resetPassword(data: object) {
    return api
      .post('/auth/password/reset', {
        ...data,
      })
      .then((response) => {
        return response.data
      })
  }
}

export default new UserService()
