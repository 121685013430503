import { Commit } from 'vuex'
import ClientService from '@/services/client.service'

export const client = {
  namespaced: true,
  state: {
    id: '',
    website: '',
    name: '',
    completeName: '',
    properties: {
      googleApiKey: '',
      displayLogo: false,
      displayDepartment: false,
      gtmId: '',
      map: false,
      resultsPerPage: 10,
      facebookAppId: '',
      spontaneousWid: '',
      enableLoginRegister: false,
      kickMyBot: false
    },
    design: {
      logo: '',
      primaryColor: '',
      secondaryColor: '',
      backgroundImage: '',
      companyPresentation: '',
      spontaneousApplicationText: '',
      favicon: '',
      sidebarMedia : {
        type: '',
        url: ''
      }
    },
    ssrJobDetailsEndpoint: '/html/job/'
  },
  actions: {
    getClientInformation({ commit }: { commit: Commit }) {
      return ClientService.getClientInformation().then(
        (response: any) => {
          commit('setClient', response)
          return Promise.resolve(response)
        },
        (error: any) => {
          return Promise.reject(error)
        }
      )
    },
    getClientLegalMentions() {
      return ClientService.getClientLegalMentions().then(
        (response: any) => {
          return Promise.resolve(response)
        },
        (error: any) => {
          return Promise.reject(error)
        }
      )
    },
    getClientPersonalData() {
      return ClientService.getClientPersonalData().then(
        (response: any) => {
          return Promise.resolve(response)
        },
        (error: any) => {
          return Promise.reject(error)
        }
      )
    },
    getClientCookies() {
      return ClientService.getClientCookies().then(
          (response: any) => {
            return Promise.resolve(response)
          },
          (error: any) => {
            return Promise.reject(error)
          }
      )
    },
  },
  mutations: {
    setClient(state, payload) {
      state.id = payload.id
      state.website = payload.website
      state.name = payload.name
      state.completeName = payload.completeName
      state.design = payload.design
      state.properties = payload.properties
    },
  },
}
