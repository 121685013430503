import LocalStorageType from '../types/LocalStorage'

class TokenService {
  getLocalRefreshToken() {
    if (localStorage.getItem('refreshToken')) {
      const refreshToken = JSON.parse(localStorage.getItem('refreshToken') || '')
      return refreshToken
    } else {
      return null
    }
  }
  getLocalAccessToken() {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(localStorage.getItem('token') || '')
      return token
    } else return null
  }
  updateLocalAccessToken(refreshToken: string) {
    localStorage.removeItem('refreshToken')
    localStorage.setItem('refreshToken', JSON.stringify(refreshToken) || '')
  }
  setToken(token: string) {
    localStorage.setItem('token', JSON.stringify(token) || '')
  }
  setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', JSON.stringify(refreshToken) || '')
  }
  removeTokens() {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
  }
}
export default new TokenService()
