import api from './api'

class JobsService {
  getJobs(formData) {
    return api.post('job/list', formData).then((response) => {
      return response.data
    })
  }
  getJob(id) {
    return api.get(`job/${id}`).then((response) => {
      return response.data
    })
  }
  getSimilarJobs(id) {
    return api.get(`job/${id}/similarJobs`).then((response) => {
      return response.data
    })
  }
  getFrontPageAds() {
    return api.get(`job/frontPageAds`).then((response) => {
      return response.data
    })
  }
  getFiltersForm() {
    return api.get('job/filtersForm').then((response) => {
      return response.data
    })
  }
}

export default new JobsService()
