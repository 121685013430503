import { Commit } from 'vuex'
import ApplyService from '@/services/apply.service'
import JobsService from '@/services/jobs.service'

export const apply = {
  namespaced: true,
  actions: {
    applyJob({ commit }: { commit: Commit }, data: any) {
      return ApplyService.applyToJob(data).then(
        (response: any) => {
          commit('applyJobSuccess', response)
          return Promise.resolve(response)
        },
        (error: any) => {
          commit('applyJobFailure', error)
          return Promise.reject(error)
        }
      )
    },
    getApplicationCategories({ commit }: { commit: Commit }, lang: string) {
      return ApplyService.getApplicationCategories(lang).then(
        (response) => {
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getApplications({ commit }: { commit: Commit }) {
      return ApplyService.getApplications().then(
        (response: any) => {
          commit('getApplicationsSuccess', response)
          return Promise.resolve(response)
        },
        (error: any) => {
          commit('getApplicationsFailure', error)
          return Promise.reject(error)
        }
      )
    },
  },
  mutations: {
    applyJobSuccess(response: any) {},
    applyJobFailure(response: any) {},
    getApplicationsSuccess(response: any) {},
    getApplicationsFailure(response: any) {},
  },
  methods: {},
}
