import axiosInstance from './api'
import TokenService from './token.service'
import { Store } from 'vuex'
import router from '../router'

const setup = (store: Store<any>) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken()
      if (token && !config["_retry"]) {
        if (config.headers) {
          config.headers['Authorization'] = 'Bearer ' + token
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    (err) => {
      const originalConfig = err.config
      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (
          err.response.status === 401 &&
          !originalConfig._retry
        ) {
              originalConfig._retry = true
              return refreshAccessToken().then((response: any) => {
                TokenService.setRefreshToken(response.data.refreshToken)
                TokenService.setToken(response.data.accessToken)
                originalConfig.headers['Authorization'] = 'Bearer ' + response.data.accessToken
                return axiosInstance(originalConfig);
              });
        } else if (err.response.status === 403) {
          store.dispatch('auth/logout')
        }
      }
      return Promise.reject(err)
    }
  )

  async function refreshAccessToken() {
    try {
      const response = await axiosInstance.post("/auth/token/refresh", {
        refreshToken: TokenService.getLocalRefreshToken()
      });
      return response
    } catch (err) {
      throw err;
    }
  }
}
export default setup
