import AuthService from '@/services/auth.service'
import TokenService from '@/services/token.service'
import ResponseType from '../../types/Response'
import { Commit } from 'vuex'
import { ValidationFormType } from '@/types/Validation'
import i18n from "@/main";
import {RefreshTokenFormType} from "@/types/AuthType";

interface AuthType {
  loggedIn: boolean,
  token: string
}

export const auth = {
  namespaced: true,
  state: {
    loggedIn: '',
  },
  actions: {
    login(
      { commit }: { commit: Commit },
      user: { username: string; password: string }
    ) {
      return AuthService.login(user).then(
        (user: ResponseType) => {
          commit('loginSuccess')
          return Promise.resolve(user)
        },
        (error: ResponseType) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout({ commit }: { commit: Commit }) {
      AuthService.logout()
      commit('logout')
      commit('user/clear', null, { root: true })
    },
    register(
      { commit }: { commit: Commit },
      user: {
        email: string
        password: string
        firstname: string
        lastname: string
      }
    ) {
      return AuthService.register(user).then(
        (response: ResponseType) => {
            commit(
                'snackbar/show',
                {
                    content: i18n.global.t('page.signup.form.success'),
                    color: 'success',
                },
                { root: true }
            )
          return Promise.resolve(response.data)
        },
        (error: ResponseType) => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    },
    validation(
      { commit }: { commit: Commit },
      validationFormType: ValidationFormType
    ) {
      return AuthService.validation(validationFormType).then(
        (response: ResponseType) => {
          commit('validationSuccess', response)
          return Promise.resolve(response)
        },
        (error: ResponseType) => {
          commit('validationFailure')
          return Promise.reject(error)
        }
      )
    },
    resendUserToken({ commit }: { commit: Commit }, userToken: string) {
      return AuthService.resendUserToken(userToken).then(
        (response: ResponseType) => {
          commit('resendUserTokenSuccess')
          return Promise.resolve(response.data)
        },
        (error: ResponseType) => {
          commit('resendUserTokenFailure')
          return Promise.reject(error)
        }
      )
    },
    refreshToken(
      { commit }: { commit: Commit },
       refreshTokenFormType: RefreshTokenFormType
    ) {
      return AuthService.refreshToken(refreshTokenFormType).then(
          (response: ResponseType) => {
            return Promise.resolve(response.data)
          },
          (error: ResponseType) => {
            return Promise.reject(error)
          }
      )
    },
  },
  mutations: {
    loginSuccess(state: AuthType) {
      state.loggedIn = true
    },
    loginFailure(state: AuthType) {
      state.loggedIn = false
    },
    logout(state: AuthType) {
      state.loggedIn = false
    },
    registerSuccess(state: AuthType) {
      state.loggedIn = false
    },
    registerFailure(state: AuthType) {
      state.loggedIn = false
    },
    validationSuccess(state: AuthType) {
      state.loggedIn = true
    },
    validationFailure(state: AuthType) {
      state.loggedIn = false
    },
    refreshToken(state: AuthType, token: string) {
      state.loggedIn = true
      if (token) {
        state.token = token
      }
    },
    resendUserTokenSuccess(state: AuthType) {},
    resendUserTokenFailure(state: AuthType) {},
  },
}
