import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "banner-container" }
const _hoisted_2 = { class: "banner-title" }
const _hoisted_3 = { class: "d-flex justify-space-between align-center pb-4" }
const _hoisted_4 = { class: "d-none d-md-flex flex-row banner-action" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "banner-container" }
const _hoisted_8 = { class: "search-return" }
const _hoisted_9 = { class: "main-input" }
const _hoisted_10 = { class: "second-input" }
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = { class: "d-flex flex-column" }
const _hoisted_13 = { class: "mobile-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_VDivider = _resolveComponent("VDivider")!
  const _component_VListSubheader = _resolveComponent("VListSubheader")!
  const _component_VCheckboxBtn = _resolveComponent("VCheckboxBtn")!
  const _component_VListItem = _resolveComponent("VListItem")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      class: "banner d-none d-md-flex",
      style: _normalizeStyle({
      background:
        'url(' + _ctx.client.design.backgroundImage + ')',
        'background-position': 'center'
    })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$tc('page.jobs.banner.title')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_text_field, {
            class: "mr-6",
            "prepend-inner-icon": "mdi-magnify",
            modelValue: _ctx.keywordsValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keywordsValue) = $event)),
            placeholder: _ctx.$t('page.jobs.input.keywords.placeholder'),
            variant: "outlined"
          }, null, 8, ["modelValue", "placeholder"]),
          _createVNode(_component_v_autocomplete, {
            class: "mr-6",
            "prepend-inner-icon": "mdi-map-marker-outline",
            modelValue: _ctx.locationsValue,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.locationsValue) = $event)),
            disabled: _ctx.isUpdating,
            items: _ctx.facets.location,
            placeholder: _ctx.$t('page.jobs.input.location.placeholder'),
            "custom-filter": _ctx.locationFilter,
            multiple: "",
            height: "10",
            variant: "outlined"
          }, {
            item: _withCtx(({ props, item }) => [
              (typeof item.raw === 'object' && 'divider' in item.raw)
                ? (_openBlock(), _createBlock(_component_VDivider, { key: 0 }))
                : (typeof item.raw === 'object' && 'header' in item.raw)
                  ? (_openBlock(), _createBlock(_component_VListSubheader, {
                      key: 1,
                      title: item.raw.header
                    }, null, 8, ["title"]))
                  : (_openBlock(), _createBlock(_component_VListItem, _mergeProps({ key: 2 }, props, {
                      label: item.title,
                      value: item.value
                    }), {
                      prepend: _withCtx((status) => [
                        (_openBlock(), _createBlock(_component_VCheckboxBtn, {
                          key: item.value,
                          modelValue: status.isSelected,
                          ripple: false,
                          tabindex: "-1"
                        }, null, 8, ["modelValue"]))
                      ]),
                      _: 2
                    }, 1040, ["label", "value"]))
            ]),
            selection: _withCtx(({ item, index }) => [
              (index === 0)
                ? (_openBlock(), _createBlock(_component_v_chip, {
                    key: 0,
                    closable: "",
                    color: _ctx.client.design.primaryColor,
                    "onClick:close": _cache[1] || (_cache[1] = ($event: any) => (_ctx.locationsValue = []))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.locationsValue.length) + " " + _toDisplayString(_ctx.$tc('page.jobs.chip.label', _ctx.locationsValue.length > 1 ? 2 : 1)), 1)
                    ]),
                    _: 1
                  }, 8, ["color"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "disabled", "items", "placeholder", "custom-filter"]),
          _createVNode(_component_v_btn, {
            small: "",
            class: "btn btn-primary col btn-banner-search",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "fa-search" })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        (_ctx.moreCriteria)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([_ctx.facets.categories.length > 0 ? 'd-none d-md-flex pb-4 w-75' : 'd-none d-md-flex pb-4 w-50'])
            }, [
              (_ctx.facets.categories.length > 0)
                ? (_openBlock(), _createBlock(_component_v_autocomplete, {
                    key: 0,
                    class: "input-header mr-3",
                    modelValue: _ctx.categoriesValue,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.categoriesValue) = $event)),
                    items: _ctx.facets.categories,
                    placeholder: _ctx.$t('page.jobs.input.categories.placeholder'),
                    "prepend-inner-icon": "mdi-format-list-bulleted",
                    multiple: "",
                    variant: "outlined"
                  }, {
                    selection: _withCtx(({ item, index }) => [
                      (index === 0)
                        ? (_openBlock(), _createBlock(_component_v_chip, {
                            key: 0,
                            closable: "",
                            color: _ctx.client.design.primaryColor,
                            "onClick:close": _cache[3] || (_cache[3] = ($event: any) => (_ctx.categoriesValue = []))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.categoriesValue.length) + " " + _toDisplayString(_ctx.$tc('page.jobs.chip.label', _ctx.categoriesValue.length > 1 ? 2 : 1)), 1)
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "items", "placeholder"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_autocomplete, {
                class: "input-header mr-3",
                modelValue: _ctx.contractTypesValue,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.contractTypesValue) = $event)),
                items: _ctx.facets.contractType,
                placeholder: _ctx.$t('page.jobs.input.contractType.placeholder'),
                "prepend-inner-icon": "mdi-file-document-edit-outline",
                multiple: "",
                variant: "outlined"
              }, {
                selection: _withCtx(({ item, index }) => [
                  (index === 0)
                    ? (_openBlock(), _createBlock(_component_v_chip, {
                        key: 0,
                        closable: "",
                        color: _ctx.client.design.primaryColor,
                        "onClick:close": _cache[5] || (_cache[5] = ($event: any) => (_ctx.contractTypesValue = []))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.contractTypesValue.length) + " " + _toDisplayString(_ctx.$tc('page.jobs.chip.label', _ctx.contractTypesValue.length > 1 ? 2 : 1)), 1)
                        ]),
                        _: 1
                      }, 8, ["color"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "items", "placeholder"]),
              _createVNode(_component_v_text_field, {
                class: "input-header input-salary",
                "prepend-inner-icon": "mdi-cash",
                placeholder: _ctx.$t('page.jobs.input.salary.placeholder'),
                variant: "outlined"
              }, null, 8, ["placeholder"])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_btn, {
            class: "btn mr-4 btn-secondary btn-filter",
            "prepend-inner-icon": "mdi-briefcase",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.moreCriteria = !_ctx.moreCriteria))
          }, {
            default: _withCtx(() => [
              _cache[15] || (_cache[15] = _createElementVNode("span", { class: "mdi mdi-filter-outline" }, null, -1)),
              (_ctx.moreCriteria)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("page.jobs.buttons.less-criteria")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("page.jobs.buttons.more-criteria")), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            class: "btn btn-clear btn-secondary",
            onClick: _ctx.clearAll
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "fa-rotate-right",
                "aria-hidden": "true",
                class: "mr-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("page.jobs.buttons.reset")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ], 4),
    _createElementVNode("section", {
      class: _normalizeClass(["banner banner-mobile banner-mobile-active d-flex d-sm-flex d-md-none pb-3", { active: _ctx.focusedClass }])
    }, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_btn, {
            class: "",
            onClick: _ctx.leaveSearch
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "fa-arrow-left" })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _cache[16] || (_cache[16] = _createElementVNode("h2", { class: "d-flex align-center mb-0" }, "Ma recherche", -1))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_v_text_field, {
            "prepend-inner-icon": "mdi-magnify",
            modelValue: _ctx.keywordsValue,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.keywordsValue) = $event)),
            placeholder: _ctx.$t('page.jobs.input.keywords.placeholder'),
            onFocus: _ctx.focus,
            onBlur: _ctx.blur,
            variant: "outlined"
          }, null, 8, ["modelValue", "placeholder", "onFocus", "onBlur"]),
          _createVNode(_component_v_autocomplete, {
            class: "input-location mt-5",
            "prepend-inner-icon": "mdi-map-marker-outline",
            modelValue: _ctx.locationsValue,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.locationsValue) = $event)),
            disabled: _ctx.isUpdating,
            items: _ctx.facets.location,
            placeholder: _ctx.$t('page.jobs.input.location.placeholder'),
            "custom-filter": _ctx.locationFilter,
            multiple: "",
            height: "10",
            variant: "outlined"
          }, {
            item: _withCtx(({ props, item }) => [
              (typeof item.raw === 'object' && 'divider' in item.raw)
                ? (_openBlock(), _createBlock(_component_VDivider, { key: 0 }))
                : (typeof item.raw === 'object' && 'header' in item.raw)
                  ? (_openBlock(), _createBlock(_component_VListSubheader, {
                      key: 1,
                      title: item.raw.header
                    }, null, 8, ["title"]))
                  : (_openBlock(), _createBlock(_component_VListItem, _mergeProps({ key: 2 }, props, {
                      label: item.title,
                      value: item.value
                    }), {
                      prepend: _withCtx((status) => [
                        (_openBlock(), _createBlock(_component_VCheckboxBtn, {
                          key: item.value,
                          modelValue: status.isSelected,
                          ripple: false,
                          tabindex: "-1"
                        }, null, 8, ["modelValue"]))
                      ]),
                      _: 2
                    }, 1040, ["label", "value"]))
            ]),
            selection: _withCtx(({ item, index }) => [
              (index === 0)
                ? (_openBlock(), _createBlock(_component_v_chip, {
                    key: 0,
                    closable: "",
                    color: _ctx.client.design.primaryColor,
                    "onClick:close": _cache[9] || (_cache[9] = ($event: any) => (_ctx.locationsValue = []))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.locationsValue.length) + " " + _toDisplayString(_ctx.$tc('page.jobs.chip.label', _ctx.locationsValue.length > 1 ? 2 : 1)), 1)
                    ]),
                    _: 1
                  }, 8, ["color"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "disabled", "items", "placeholder", "custom-filter"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_ctx.facets.categories.length > 0)
              ? (_openBlock(), _createBlock(_component_v_autocomplete, {
                  key: 0,
                  modelValue: _ctx.categoriesValue,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.categoriesValue) = $event)),
                  items: _ctx.facets.categories,
                  placeholder: _ctx.$t('page.jobs.input.categories.placeholder'),
                  class: "input-header mb-5",
                  "prepend-inner-icon": "mdi-file-document-edit-outline",
                  multiple: "",
                  variant: "outlined"
                }, {
                  selection: _withCtx(({ item, index }) => [
                    (index === 0)
                      ? (_openBlock(), _createBlock(_component_v_chip, {
                          key: 0,
                          closable: "",
                          color: _ctx.client.design.primaryColor,
                          "onClick:close": _cache[11] || (_cache[11] = ($event: any) => (_ctx.categoriesValue = []))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.categoriesValue.length) + " " + _toDisplayString(_ctx.$tc(
                'page.jobs.chip.label',
                _ctx.categoriesValue.length > 1 ? 2 : 1
                )), 1)
                          ]),
                          _: 1
                        }, 8, ["color"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue", "items", "placeholder"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_v_autocomplete, {
              modelValue: _ctx.contractTypesValue,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.contractTypesValue) = $event)),
              items: _ctx.facets.contractType,
              placeholder: _ctx.$t('page.jobs.input.contractType.placeholder'),
              class: "input-header mb-5",
              "prepend-inner-icon": "mdi-file-document-edit-outline",
              multiple: "",
              variant: "outlined"
            }, {
              selection: _withCtx(({ item, index }) => [
                (index === 0)
                  ? (_openBlock(), _createBlock(_component_v_chip, {
                      key: 0,
                      closable: "",
                      color: _ctx.client.design.primaryColor,
                      "onClick:close": _cache[13] || (_cache[13] = ($event: any) => (_ctx.contractTypesValue = []))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.contractTypesValue.length) + " " + _toDisplayString(_ctx.$tc(
                'page.jobs.chip.label',
                _ctx.contractTypesValue.length > 1 ? 2 : 1
                )), 1)
                      ]),
                      _: 1
                    }, 8, ["color"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "items", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_v_btn, {
            small: "",
            class: "btn btn-primary col btn-banner-search",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "fa-search" })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ], 2)
  ], 64))
}