export const snackbar = {
  namespaced: true,
  state: {
    content: '',
    color: '',
    show: false,
  },
  mutations: {
    show(state, payload) {
      state.color = payload.color
      state.content = payload.content
      state.show = true
    },
  },
}
