import { createWebHistory, createRouter } from 'vue-router'
import { RouteRecordRaw } from 'vue-router'

//views
import Login from '../views/Auth/LoginView.vue'
import Register from '../views/Auth/RegisterView.vue'
import Validation from '../views/Auth/ValidationView.vue'
import Account from '../views/Account/AccountView.vue'
import Job from '../views/Job/Job.vue'
import NotFound from '../components/Error/NotFound.vue'
import ForgotPassword from '../views/Auth/ForgotPassword.vue'
import ResetPassword from '../views/Auth/ResetPassword.vue'
import LegalMentions from '@/views/RGPD/LegalMentions.vue'
import PersonalData from '@/views/RGPD/PersonalData.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/jobs',
  },
  {
    path: '/login',
    name: 'LoginView',
    component: Login,
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: Register,
  },
  {
    path: '/validation/:tokenId',
    name: 'ValidationView',
    component: Validation,
  },
  {
    path: '/account',
    name: 'AccountView.vue',
    component: Account,
  },
  {
    path: '/job/:jobId',
    name: 'JobView',
    component: Job,
  },
  {
    path: '/notFound',
    name: 'NotFoundView',
    component: NotFound,
  },
  {
    path: '/legal-mentions',
    name: 'LegalMentionsView',
    component: LegalMentions,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordView',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPasswordView',
    component: ResetPassword,
  },
  {
    path: '/personal-data',
    name: 'PersonalDataView',
    component: PersonalData,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,

})
export default router
