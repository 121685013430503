import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "flex p-0 pt-0 offers-section" }
const _hoisted_3 = { class: "offers-container" }
const _hoisted_4 = { class: "pagination d-flex justify-md-end justify-center" }
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "company-image"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "mb-6 spontaneous-application-title" }
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JobsFilter = _resolveComponent("JobsFilter")!
  const _component_JobsList = _resolveComponent("JobsList")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_MultiStepFormApplyModal = _resolveComponent("MultiStepFormApplyModal")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_TarteAuCitron = _resolveComponent("TarteAuCitron")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_JobsFilter, {
        facets: _ctx.facets,
        onSubmit: _ctx.submit,
        onKeywords: _cache[0] || (_cache[0] = ($event: any) => (_ctx.keywords = $event)),
        onLocations: _cache[1] || (_cache[1] = ($event: any) => (_ctx.locations = $event)),
        onContractTypes: _cache[2] || (_cache[2] = ($event: any) => (_ctx.contractTypes = $event)),
        onCategories: _cache[3] || (_cache[3] = ($event: any) => (_ctx.categories = $event))
      }, null, 8, ["facets", "onSubmit"]),
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "12",
                md: "8",
                class: "offers-liste"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_JobsList, {
                    jobs: _ctx.jobs,
                    jobsFound: _ctx.jobsFound
                  }, null, 8, ["jobs", "jobsFound"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_v_pagination, {
                      modelValue: _ctx.page,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.page) = $event)),
                      length: _ctx.numPage,
                      "total-visible": _ctx.numVisiblePage,
                      size: "small"
                    }, null, 8, ["modelValue", "length", "total-visible"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "12",
                md: "4",
                class: "sidebar-company pt-2 pt-md-8"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("section", _hoisted_5, [
                    _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("page.jobs.who-are-we")), 1),
                    (
                  _ctx.client.design.sidebarMedia &&
                  _ctx.client.design.sidebarMedia.type === 1
                )
                      ? (_openBlock(), _createElementBlock("iframe", {
                          key: 0,
                          class: "mb-6",
                          width: "320",
                          height: "180",
                          src: _ctx.client.design.sidebarMedia.url
                        }, null, 8, _hoisted_7))
                      : (
                  _ctx.client.design.sidebarMedia &&
                  _ctx.client.design.sidebarMedia.type === 0
                )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("img", {
                              src: _ctx.client.design.sidebarMedia.url
                            }, null, 8, _hoisted_9)
                          ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("p", {
                      class: "small mb-8",
                      innerHTML: _ctx.client.design.companyPresentation
                    }, null, 8, _hoisted_10),
                    (_ctx.client.properties.spontaneousWid)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t("page.jobs.brand.description.3")), 1),
                          _createElementVNode("p", {
                            class: "small mb-6",
                            innerHTML: _ctx.client.design.spontaneousApplicationText
                          }, null, 8, _hoisted_13),
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            type: "button",
                            class: "btn btn-primary",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.dataModal = true))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("page.jobs.buttons.spontaneous-application")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_MultiStepFormApplyModal, {
                            onCloseModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dataModal = false)),
                            "show-modal": _ctx.dataModal,
                            "job-id": 0
                          }, null, 8, ["show-modal"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_component_TarteAuCitron)
  ], 64))
}