
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import ResponseType from '../../types/Response'
import DocumentType from "@/types/DocumentType";

const __default__ = defineComponent({
  name: 'LoginForm',
  data() {
    return {
      valid: true,
      username: '',
      usernameRule: [
        (v) => !!v || this.$t('page.login.form.username.required'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('page.login.form.username.invalid'),
      ],
      password: '',
      passwordRule: [
        (v) => !!v || this.$t('page.login.form.password.required'),
      ],
      loading: false,
      message: '',
    }
  },
  components: {},
  methods: {
    async validate() {
      const form = this.$refs.loginForm as any
      if (form.validate()) this.handleLogin()
    },
    handleLogin() {
      this.loading = true
      let loginData = {
        username: this.username,
        password: this.password,
      }
      this.$store.dispatch('auth/login', loginData).then(
        () => {
          this.$store.dispatch('user/getUser').then(
            () => {
              this.loading = false
              this.getProfilePicture()
              this.$router.push('/account')
            }
          )
        },
        (error: ResponseType) => {
          this.loading = false
          this.message = this.$t('page.login.form.errorMessage')
        }
      )
    },
    getProfilePicture() {
      const profilePictureDocuments = this.getProfilePictureDocumentFromState()
      if (profilePictureDocuments.length > 0) {
        this.$store.dispatch('user/generatePresignedUrl', profilePictureDocuments[0].path)
      }
    },
    getProfilePictureDocumentFromState: function (): Array<DocumentType> {
      return this.$store.state.user.documents?.filter(function (document: DocumentType) {
        return document.documentType === 'PP'
      })
    },
  },
  mounted() {},
  computed: {
    ...mapState(['client']),
    currentUrl() {
      return window.location.href
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "029c4654": (_ctx.client.design.primaryColor),
  "474c8749": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__