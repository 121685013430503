
import { defineComponent } from 'vue'
import ResponseType from '../../types/Response'

export default defineComponent({
  name: 'DeleteMyAccount',
  data() {
    return {
      loading: false,
      error: false,
      message: '',
    }
  },
  props: {
    visible: Boolean,
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  methods: {
    submit() {
      this.loading = true
      this.$store.dispatch('user/deleteUserAccount').then(
              (data: ResponseType) => {
                this.loading = false
                this.error = false
                this.message = ''
                this.$store.dispatch('auth/logout')
              },
              (error: ResponseType) => {
                this.loading = false
                this.error = true
                this.message = this.$t('page.user.security.deleteMyAccount.form.error.exception')
              }
      )
    }
  }
})
