
import { defineComponent } from 'vue'
import RegisterForm from '../../components/Register/RegisterForm.vue'

export default defineComponent({
  name: 'RegisterView',
  components: {
    RegisterForm,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
})
