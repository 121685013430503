import { nextTick } from 'vue'
import { createI18n, I18n } from 'vue-i18n'

interface OptionsI18n {
  availableLocales: Array<string>
  fallbackLocale: string
}

export async function loadLocaleMessages(i18n: I18n, locale: string) {
  // load locale messages with dynamic import
  const messages = await import('../locales/' + locale + '.json')
  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

export const SUPPORT_LOCALES = ['en', 'fr', 'es', 'it']

export function setupI18n(options: OptionsI18n) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, 'en')
  return i18n
}

export function setI18nLanguage(i18n: any, locale: string) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }

  document?.querySelector('html')?.setAttribute('lang', locale)
}
