
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
const __default__ = defineComponent({
  name: 'ResetPasswordView',
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      message: '',
      error: false,
    }
  },
  methods: {
    required: function (value) {
      if (value) {
        return true
      } else {
        return this.$t('page.reset_password.form.password.required')
      }
    },
    min6: function (value) {
      if (value.length >= 6) {
        return true
      } else {
        return this.$tc('page.reset_password.form.password.min', 6)
      }
    },
    matchingPasswords: function () {
      if (this.newPassword === this.confirmPassword) {
        return true
      } else {
        return this.$t('page.reset_password.form.password_matches')
      }
    },
    submitForm: function () {
      let token = this.$route.params.token
        this.$store
          .dispatch('user/resetPassword', {
            password: this.newPassword,
            token: token,
          }).then(
            (data: ResponseType) => {
              this.$router.push('/login')
              this.error = false
            },
            (error: ResponseType) => {
              this.error = true
              this.message = this.$t('page.reset_password.form.error')
            }
        )
    },
  },
  watch: {},
  computed: {
    ...mapState(['client']),
    passRule: function () {
      if (this.newPassword === '' || this.confirmPassword === '') {
        return false
      } else if (
        this.min6(this.newPassword) === true &&
        this.min6(this.confirmPassword) === true && this.confirmPassword == this.newPassword
      ) {
        return true
      } else {
        return false
      }
    },
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ea41774e": (_ctx.client.design.primaryColor),
  "064a000c": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__