import { Commit } from 'vuex'
import JobsService from '@/services/jobs.service'

export const jobs = {
  namespaced: true,
  state: {},
  actions: {
    getJobs({ commit }: { commit: Commit }, formData: any) {
      return JobsService.getJobs(formData).then(
        (response) => {
          commit('getJobsSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          commit('getJobsFailure', error)
          return Promise.reject(error)
        }
      )
    },
    getJob({ commit }: { commit: Commit }, id: string) {
      return JobsService.getJob(id).then(
        (response) => {
          commit('getJobSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          commit('getJobFailure', error)
          return Promise.reject(error)
        }
      )
    },
    getSimilarJobs({ commit }: { commit: Commit }, id: string) {
      return JobsService.getSimilarJobs(id).then(
        (response) => {
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getFrontPageAds({ commit }: { commit: Commit }) {
      return JobsService.getFrontPageAds().then(
        (response) => {
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    getFiltersForm({ commit }: { commit: Commit }) {
      return JobsService.getFiltersForm().then(
        (response) => {
          commit('getFiltersFormSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          commit('getFiltersFormFailure', error)
          return Promise.reject(error)
        }
      )
    },
  },
  mutations: {
    getJobsSuccess(response: any) {},
    getJobsFailure(response: any) {},
    getJobSuccess(response: any) {},
    getJobFailure(response: any) {},
    getFiltersFormSuccess(response: any) {},
    getFiltersFormFailure(response: any) {},
  },
}
