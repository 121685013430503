
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import { ProfileType } from "@/types/ProfileType";
import UserService from "@/services/user.service"

export default defineComponent({
  name: 'UpdateProfileForm',
  data() {
    return {
      error: false,
      message: '',
      loading: false,
      genderCodeItems: [
        { text: this.$t('page.user.profile.form.genderCode.male'), value: 0 },
        { text: this.$t('page.user.profile.form.genderCode.female'), value: 1 },
      ],
      firstnameRule: [
        (v) => !!v || this.$t('page.user.profile.form.firstname.required'),
        (v) =>
                (v && v.length <= 20) ||
                this.$tc('page.user.profile.form.firstname.max', 20),
        (v) =>
                (v && v.length >= 3) ||
                this.$tc('page.user.profile.form.firstname.min', 3),
      ],
      lastnameRule: [
        (v) => !!v || this.$t('page.user.profile.form.lastname.required'),
        (v) =>
                (v && v.length <= 20) ||
                this.$tc('page.user.profile.form.lastname.max', 20),
        (v) =>
                (v && v.length >= 3) || this.$tc('page.user.profile.form.lastname.min', 3),
      ],
    }
  },
  methods: {
    submit() {
      this.loading = true
      this.$store.dispatch('user/updateProfile', this.profile).then(
              (data: ProfileType) => {
                this.$emit('close')
                this.loading = false
                this.error = false
                this.message = ''
              },
              (error: ResponseType) => {
                this.loading = false
                this.error = true
                this.message = this.$t('page.user.profile.form.errorMessage')
              }
      )
    },
  },
  props: {
    visible: Boolean,
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    ...mapGetters({
      profile: 'user/profile'
    }),
    ...mapState(['client'])
  },
})
