import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-3 pb-0" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_4 = { class: "mb-3 text-center" }
const _hoisted_5 = { class: "spinner-border spinner-border-sm mr-2" }
const _hoisted_6 = { class: "pl-0" }
const _hoisted_7 = { class: "tag mb-0 mt-1" }
const _hoisted_8 = {
  class: "text-center",
  style: {"width":"20px","padding":"0px"}
}
const _hoisted_9 = {
  class: "text-center pr-0",
  style: {"width":"20px","padding":"0px"}
}
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_12 = { class: "mb-3 text-center" }
const _hoisted_13 = { class: "spinner-border spinner-border-sm mr-2" }
const _hoisted_14 = { class: "pl-0" }
const _hoisted_15 = { class: "tag mb-0 mt-1" }
const _hoisted_16 = {
  class: "text-center",
  style: {"width":"20px","padding":"0px"}
}
const _hoisted_17 = {
  class: "text-center pr-0",
  style: {"width":"20px","padding":"0px"}
}
const _hoisted_18 = {
  key: 0,
  class: "alert alert-danger mt-3",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.show) = $event)),
    width: "800px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, {
            class: "d-flex justify-content-between",
            style: _normalizeStyle({ background: _ctx.primaryColor, color: 'white'})
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('page.user.documents.form.title')), 1),
              _createVNode(_component_v_btn, {
                right: "",
                icon: "mdi-close",
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
              })
            ]),
            _: 1
          }, 8, ["style"]),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('page.user.documents.form.cv')), 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("input", {
                      type: "file",
                      id: "cvInputs",
                      ref: "cvInputs",
                      style: {"display":"none"},
                      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.uploadCV && _ctx.uploadCV(...args))),
                      tabindex: "-1"
                    }, null, 544),
                    _createVNode(_component_v_btn, {
                      class: "btn btn-primary",
                      type: "button",
                      color: _ctx.primaryColor,
                      style: _normalizeStyle({ 'background-color': _ctx.primaryColor + '!important', color: 'white'}),
                      disabled: _ctx.loadingCV,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.cvInputs.click()))
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("span", _hoisted_5, null, 512), [
                          [_vShow, _ctx.loadingCV]
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('page.user.documents.form.add')), 1)
                      ]),
                      _: 1
                    }, 8, ["color", "style", "disabled"])
                  ])
                ]),
                _cache[10] || (_cache[10] = _createElementVNode("hr", { class: "m-0 mb-4" }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cvDocuments, (document) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: document.path
                  }, [
                    _createVNode(_component_v_row, { class: "row-doc" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "6",
                          sm: "8",
                          md: "10"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("span", _hoisted_7, [
                                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "mdi mdi-file-edit-outline" }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(document.name), 1)
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          cols: "3",
                          sm: "2",
                          md: "1"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_v_btn, {
                                class: "btn",
                                onClick: ($event: any) => (_ctx.getDocument(document)),
                                variant: "text"
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createElementVNode("span", { class: "mdi mdi-eye-outline" }, null, -1)
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          cols: "3",
                          sm: "2",
                          md: "1"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_v_btn, {
                                onClick: ($event: any) => (_ctx.deleteDocument(document)),
                                variant: "text"
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createElementVNode("span", { class: "mdi mdi-delete-outline" }, null, -1)
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1))
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('page.user.documents.form.lm')), 1),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("input", {
                      type: "file",
                      id: "mlInput",
                      ref: "mlInput",
                      style: {"display":"none"},
                      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.uploadML && _ctx.uploadML(...args)))
                    }, null, 544),
                    _createVNode(_component_v_btn, {
                      class: "btn btn-primary",
                      color: _ctx.primaryColor,
                      disabled: _ctx.loadingML,
                      style: _normalizeStyle({ 'background-color': _ctx.primaryColor + '!important', color: 'white'}),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$refs.mlInput.click()))
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("span", _hoisted_13, null, 512), [
                          [_vShow, _ctx.loadingML]
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('page.user.documents.form.add')), 1)
                      ]),
                      _: 1
                    }, 8, ["color", "disabled", "style"])
                  ])
                ]),
                _cache[15] || (_cache[15] = _createElementVNode("hr", { class: "m-0 mb-4" }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.motivationLetterDocuments, (document) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: document.path
                  }, [
                    _createVNode(_component_v_row, { class: "row-doc" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "6",
                          sm: "8",
                          md: "10"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("span", _hoisted_15, [
                                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "mdi mdi-file-edit-outline" }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(document.name), 1)
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          cols: "3",
                          sm: "2",
                          md: "1"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_v_btn, {
                                class: "btn",
                                onClick: ($event: any) => (_ctx.getDocument(document)),
                                variant: "text"
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createElementVNode("span", { class: "mdi mdi-eye-outline" }, null, -1)
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          cols: "3",
                          sm: "2",
                          md: "1"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_17, [
                              _createVNode(_component_v_btn, {
                                onClick: ($event: any) => (_ctx.deleteDocument(document)),
                                variant: "text"
                              }, {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createElementVNode("span", { class: "mdi mdi-delete-outline" }, null, -1)
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _cache[14] || (_cache[14] = _createElementVNode("hr", null, null, -1))
                  ]))
                }), 128))
              ]),
              (_ctx.message)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.message), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}