import api from './api'

class ClientService {
  getClientInformation() {
    return api.get('/client/information').then((response) => {
      return response.data
    })
  }
  getClientLegalMentions() {
    return api.get('/client/legal-mentions').then((response) => {
      return response.data
    })
  }
  getClientPersonalData() {
    return api.get('/client/personal-data').then((response) => {
      return response.data
    })
  }
  getClientCookies() {
    return api.get('/client/cookie').then((response) => {
      return response.data
    })
  }
}

export default new ClientService()
