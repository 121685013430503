
  import { truncate, cleanText, formatJobDate, camelCase } from '@/utils/string'
  import { defineComponent } from 'vue'
  import { mapState } from 'vuex'
  const __default__ = defineComponent({
    name: 'JobsList',
    props: ['jobs'],
    data() {
      return {
        numPage: 0,
        numVisiblePage: 5,
        page: 1,
      }
    },
    setup() {
      return { truncate, cleanText, formatJobDate, camelCase }
    },
    methods: {
      setCurrentJobId(jobId) {
        this.$emit('setCurrentJobId', jobId)
      },
    },
    computed: {
      ...mapState(['client']),
    },
  })

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "d8ab9ce8": (_ctx.client.design.primaryColor),
  "2a698e9a": (_ctx.client.design.secondaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__