
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'PersonalData',
  data() {
    return {
      personalData: '',
    }
  },
  methods: {
    getClientPersonalData() {
      this.$store
        .dispatch('client/getClientPersonalData')
        .then((response: any) => {
          this.personalData = response['personalData']
        })
    },
  },
  mounted() {
    this.getClientPersonalData()
  },
  computed: {
    ...mapState(['client']),
  },
})
