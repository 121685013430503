import api from './api'
import TokenService from './token.service'
import { RegisterFormType } from '@/types/Register'
import { ValidationFormType } from '@/types/Validation'
import { LoginFormType } from '@/types/Login'
import { RefreshTokenFormType } from "@/types/AuthType";

class AuthService {
  static login(loginData: LoginFormType): Promise<any> {
    return api
      .post('/auth/signin', {
        ...loginData,
      })
      .then((response) => {
        if (response.data) {
          TokenService.setToken(response.data.token)
          TokenService.setRefreshToken(response.data.refreshToken)
        }
        return response.data
      })
  }
  static logout() {
    TokenService.removeTokens()
  }

  static register(registerData: RegisterFormType): Promise<any> {
    return api.post('/auth/signup', {
      ...registerData,
    })
  }
  static validation(validationFormType: ValidationFormType): Promise<any> {
    return api
      .post('/auth/validation', {
        ...validationFormType,
      })
      .then((response) => {
        return response.data
      })
  }
  static resendUserToken(userToken: string): Promise<any> {
    return api.get('/auth/token/resend/' + userToken)
  }

  static refreshToken(refreshTokenFormType: RefreshTokenFormType): Promise<any> {
    return api.post('/auth/token/refresh', {
      ...refreshTokenFormType,
    })
  }
}
export default AuthService
