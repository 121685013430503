import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faPaperPlane,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faFilePdf,
  faCircleXmark,
  faDownload,
  faTimesCircle,
  faCheck,
  faMapMarker,
  faSearch,
  faEye,
  faTrash,
  faSpinner,
  faFilePen,
  faCalendarDay,
  faLocationDot,
  faTag,
  faMoneyBill,
  faEnvelope,
  faArrowLeft,
  faRotateRight,
  faFilter,
  faXmark,
  faPen,
  faPhone,
  faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons'
import {
  faLinkedinIn,
  faTwitter,
  faFacebookF,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
library.add(
  faPaperPlane,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faFilePdf,
  faCircleXmark,
  faDownload,
  faTimesCircle,
  faMapMarker,
  faCheck,
  faSearch,
  faEye,
  faTrash,
  faSpinner,
  faFilePen,
  faCalendarDay,
  faLocationDot,
  faMoneyBill,
  faTag,
  faLinkedinIn as IconDefinition,
  faTwitter as IconDefinition,
  faFacebookF as IconDefinition,
  faEnvelope,
  faWhatsapp as IconDefinition,
  faArrowLeft,
  faRotateRight,
  faFilter,
  faXmark,
  faPen,
  faPhone,
  faArrowRightFromBracket,
)
export { FontAwesomeIcon }
