
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TarteAuCitron',
  mounted() {
    this.initTarteAuCitron()
  },
  methods: {
      initTarteAuCitron() {
          this.$store
              .dispatch('client/getClientCookies')
              .then((response: any) => {
                  //@ts-ignore
                  tarteaucitron.job = tarteaucitron.job || []
                  //@ts-ignore
                  tarteaucitron.init({
                      privacyUrl: '' /* Privacy policy url */,
                      hashtag: '#tarteaucitron' /* Open the panel with this hashtag */,
                      cookieName: 'tarteaucitron' /* Cookie name */,
                      orientation:
                          'bottom' /* Banner position (top - bottom - middle - popup) */,
                      groupServices: false /* Group services by category */,
                      showAlertSmall: false /* Show the small banner on bottom right */,
                      cookieslist: false /* Show the cookie list */,
                      showIcon: true /* Show cookie icon to manage cookies */,
                      adblocker: false /* Show a Warning if an adblocker is detected */,
                      DenyAllCta: true /* Show the deny all button */,
                      iconPosition: 'BottomLeft',
                      AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
                      highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,
                      handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,
                      removeCredit: true /* Remove credit link */,
                      moreInfoLink: true /* Show more info link */,
                      useExternalCss: false /* If false, the tarteaucitron.css file will be loaded */,
                      //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */
                      readmoreLink:
                          '' /* Change the default readmore link pointing to tarteaucitron.io */,
                      mandatory: true /* Show a message about mandatory cookies */,
                  })
                  response.forEach((cookie) => {
                      //@ts-ignore
                      tarteaucitron.job.push(cookie.serviceName)
                  })
              })
      }
  }
})
