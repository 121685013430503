import api from './api'

class ApplyService {
  applyToJob(data: any) {
    // @ts-ignore
    return api
      .post('/apply/job/' + data.jobId, data.form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data
      })
  }
  getApplications() {
    return api.get('/account/history').then((response) => {
      return response.data
    })
  }
  getApplicationCategories(lang: string) {
    return api.get(`apply/application-categories/` + lang).then((response) => {
      return response.data
    })
  }
}

export default new ApplyService()
