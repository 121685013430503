
import { defineComponent } from 'vue'
import ResponseType from '../../types/Response'
import DocumentType from "@/types/DocumentType";

interface InputFileEvent extends Event {
  target: HTMLInputElement
}

export default defineComponent({
  name: 'UpdateProfilePictureForm',
  data() {
    return {
      rawProfilePic: 'http://bootdey.com/img/Content/avatar/avatar1.png',
      loading: false,
      error: false,
      message: '',
    }
  },
  props: {
    visible: Boolean,
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    profilePicture() {
      if (this.$store.state.user.profilePictureUrl) {
        return this.$store.state.user.profilePictureUrl
      } else {
        return 'http://bootdey.com/img/Content/avatar/avatar1.png'
      }
    }
  },
  methods: {
    uploadProfilePicture(event: InputFileEvent) {
      this.loading = true
      this.message = ''

      let formData = new FormData()
      if (event.target.files !== null && event.target.files.length > 0) {
        formData.append('file', event.target.files[0])
      }

      this.$store
              .dispatch('user/uploadProfilePicture', formData)
              .then((data: Array<DocumentType>) => {
                  this.getProfilePicture()
                  this.message = ''
                  this.loading = false
                },
                (error: ResponseType) => {
                  this.message = ''
                  this.loading = false
                }
              )
    },
    getProfilePicture() {
      const profilePictureDocuments = this.getProfilePictureDocumentFromState()
      if (profilePictureDocuments.length > 0) {
        this.$store.dispatch('user/generatePresignedUrl', profilePictureDocuments[0].path)
      }
    },
    getProfilePictureDocumentFromState: function (): Array<DocumentType> {
      return this.$store.state.user.documents?.filter(function (document: DocumentType) {
        return document.documentType === 'PP'
      })
    },
  }
})
