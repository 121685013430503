
import { defineComponent } from 'vue'

const __default__ = defineComponent({
  name: 'SimilarJobs',
  components: {},
  data() {
    return {
      similarJobs: [],
    }
  },
  props: {
    jobId: String,
  },
  methods: {
    getSimilarJobs() {
      this.$store.dispatch('jobs/getSimilarJobs', this.jobId).then((response: any) => {
        this.similarJobs = response.documents;
      })
    },
  },
  mounted() {
    this.getSimilarJobs()
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    secondaryColor() {
      return this.$store.state.client.design.secondaryColor
    },
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "be4b4654": (_ctx.primaryColor),
  "a787e138": (_ctx.secondaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__