import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      class: "banner",
      style: _normalizeStyle({
      background:
        'url(' + _ctx.client.design.backgroundImage + ')',
      'background-position': 'center'
    })
    }, [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('page.legalMentions.title')), 1)
    ], 4),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", { innerHTML: _ctx.legalMentions }, null, 8, _hoisted_3)
      ])
    ])
  ], 64))
}