import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-3" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "tag mr-2" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "tag mr-2" }
const _hoisted_6 = { class: "mt-5 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_UpdateDocumentsForm = _resolveComponent("UpdateDocumentsForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { class: "elevation-1 mb-8 rounded-lg card" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('components.documents.title')), 1),
        _createVNode(_component_v_card_text, { class: "pt-0 mb-4" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('components.documents.titleCV')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cvDocuments, (file) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file.path
                }, [
                  _createElementVNode("span", _hoisted_3, [
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "mdi mdi-file-edit-outline" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(file.name), 1)
                  ])
                ]))
              }), 128)),
              _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1))
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('components.documents.titleMotivationLetters')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.motivationLetterDocuments, (file) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file.path
                }, [
                  _createElementVNode("span", _hoisted_5, [
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mdi mdi-file-edit-outline" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(file.name), 1)
                  ])
                ]))
              }), 128)),
              _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_btn, {
                class: "btn btn-primary",
                size: "small",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUpdateDocumentsForm = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('components.documents.buttons.updateDocument')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_UpdateDocumentsForm, {
      visible: _ctx.showUpdateDocumentsForm,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUpdateDocumentsForm = false))
    }, null, 8, ["visible"])
  ], 64))
}