import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-3" }
const _hoisted_2 = { class: "d-flex flex-column mb-5" }
const _hoisted_3 = { class: "d-flex flex-column date-apply" }
const _hoisted_4 = { class: "d-flex flex-column job-data" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex flex-wrap justify-start" }
const _hoisted_7 = { class: "tag mr-2 mb-1" }
const _hoisted_8 = { class: "tag mb-1" }
const _hoisted_9 = { class: "d-flex flex-column align-items-end" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "text-right" }
const _hoisted_13 = { class: "pagination d-flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "elevation-1 rounded-lg mb-8 card" }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('components.application.title')), 1),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applications, (application) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_v_row, { class: "history-row" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "3",
                      sm: "3",
                      md: "2",
                      class: "d-flex flex-column justify-content-center col-img"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.returnDayDate(application.createdAt)), 1),
                          _createElementVNode("span", null, _toDisplayString(_ctx.returnMonthDate(application.createdAt)), 1),
                          _createElementVNode("span", null, _toDisplayString(_ctx.returnYearDate(application.createdAt)), 1)
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_col, {
                      cols: "9  ",
                      sm: "9",
                      md: "7"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("h3", null, _toDisplayString(application.jobTitle), 1),
                          _createElementVNode("img", {
                            class: "img-company mb-2",
                            src: application.jobLogo?application.jobLogo:_ctx.logo
                          }, null, 8, _hoisted_5),
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("span", _hoisted_7, [
                              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "mdi mdi-file-document-edit-outline" }, null, -1)),
                              _createTextVNode(" " + _toDisplayString(application.jobContractType), 1)
                            ]),
                            _createElementVNode("span", _hoisted_8, [
                              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "mdi mdi-map-marker-outline" }, null, -1)),
                              _createTextVNode(" " + _toDisplayString(application.jobCity), 1)
                            ])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "12",
                      md: "3"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, [
                          (application.status === 'Current')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("page.history.status.current")), 1))
                            : (application.status === 'Closed')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("page.history.status.closed")), 1))
                              : _createCommentVNode("", true),
                          _createElementVNode("span", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('components.application.source')) + " ", 1),
                            _createElementVNode("strong", null, _toDisplayString(application.sourceName), 1)
                          ]),
                          _createElementVNode("span", _hoisted_12, [
                            _createTextVNode(_toDisplayString(_ctx.$t('components.application.lastAction')) + " ", 1),
                            _createElementVNode("strong", null, _toDisplayString(_ctx.returnDayDate(application.updatedAt?application.updatedAt:application.createdAt)) + " " + _toDisplayString(_ctx.returnMonthDate(application.updatedAt?application.updatedAt:application.createdAt)) + " " + _toDisplayString(_ctx.returnYearDate(application.updatedAt?application.updatedAt:application.createdAt)), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1))
              ]))
            }), 256))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_v_pagination)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}