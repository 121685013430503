import { createStore } from 'vuex'
import { auth } from './modules/auth'
import { user } from './modules/user'
import { apply } from './modules/apply'
import { jobs } from './modules/jobs'
import { client } from './modules/client'
import { snackbar } from './modules/snackbar'

const store = createStore({
  state: {},
  modules: {
    auth,
    user,
    apply,
    jobs,
    client,
    snackbar,
  },
  actions: {},
  getters: {
    getClient() {
      return client
    },
  },
})

export default store
