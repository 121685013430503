
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
const __default__ = defineComponent({
  name: 'ForgotPasswordView',
  data() {
    return {
      email: '',
      isEmailValid: false,
      message: '',
      status: '',
    }
  },
  methods: {
    submitForm(e) {
      this.$store.dispatch('user/forgotPassword', this.email).then(() => {
        this.$router.push('/login')
      })
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.isEmailValid = true
      } else {
        this.isEmailValid = false
      }
    },
  },
  watch: {
    email(value) {
      this.validateEmail(value)
    },
  },
  computed: {
    ...mapState(['client']),
    currentUrl() {
      return window.location.href
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "eb77152a": (_ctx.client.design.primaryColor),
  "8712cec4": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__