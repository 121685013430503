
import { defineComponent } from 'vue'
import ResponseType from '../../types/Response'

export default defineComponent({
  name: 'UpdatePasswordForm',
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      loading: false,
      error: false,
      message: '',
      currentPasswordRule: [
        (v) =>
                !!v ||
                this.$t('page.user.security.password.form.currentPassword.required'),
      ],
      newPasswordRule: [
        (v) =>
                !!v ||
                this.$t('page.user.security.password.form.newPassword.required'),
        (v) =>
                (v && v.length <= 40) ||
                this.$tc('page.user.security.password.form.newPassword.max', 40),
        (v) =>
                (v && v.length >= 6) ||
                this.$tc('page.user.security.password.form.newPassword.min', 6),
      ],
      confirmNewPasswordRule: [
        (v) =>
                !!v ||
                this.$t(
                        'page.user.security.password.form.confirmPassword.required'
                ),
        (v) =>
                (v && v.length <= 40) ||
                this.$tc(
                        'page.user.security.password.form.confirmPassword.max',
                        40
                ),
        (v) =>
                (v && v.length >= 6) ||
                this.$tc('page.user.security.password.form.confirmPassword.min', 6),
      ],
    }
  },
  props: {
    visible: Boolean,
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  methods: {
    submit() {
      if (this.matchingPasswords()) {
        this.loading = true
        this.$store.dispatch('user/updatePassword', this.form).then(
                (data: ResponseType) => {
                  this.$emit('close')
                  this.loading = false
                  this.error = false
                  this.message = ''
                },
                (error: ResponseType) => {
                  this.loading = false
                  this.error = true
                  this.message = this.$t('page.user.security.password.form.error.incorrectPassword')
                }
        )
      }
    },
    matchingPasswords(): boolean {
      if (
              this.form.confirmNewPassword !== this.form.newPassword
      ) {
        this.error = true
        this.message = this.$t('page.user.security.password.form.error.match')
        return false
      } else {
        this.error = false
        this.message = ''
        return true
      }
    },
  },
  watch: {
    visible: function(val) {
      if(val) {
        this.form.currentPassword = '';
        this.form.newPassword = '';
        this.form.confirmNewPassword = '';
      }
    }
  }
})
