
import { defineComponent } from 'vue'
import ResponseType from '../../types/Response'


export default defineComponent({
  name: 'UpdateEmailForm',
  data() {
    return {
      form: {
        currentUsername: '',
        newUsername: '',
        confirmNewUsername: '',
      },
      loading: false,
      error: false,
      message: '',
      newEmailRule: [
        (v) =>
                !!v ||
                this.$t('page.user.security.email.form.newEmail.required'),
        (v) =>
                /.+@.+\..+/.test(v) ||
                this.$t('page.user.security.email.form.newEmail.invalid'),
        (v) =>
                (v && v.length <= 50) ||
                this.$tc('page.user.security.email.form.newEmail..max', 50),
      ],
      confirmNewEmailRule: [
        (v) =>
                !!v ||
                this.$t(
                        'page.user.security.email.form.confirmEmail.required'
                ),
        (v) =>
                /.+@.+\..+/.test(v) ||
                this.$t('page.user.security.email.form.confirmEmail.invalid'),
        (v) =>
                (v && v.length <= 50) ||
                this.$tc(
                        'page.user.security.email.form.confirmEmail.max',
                        50
                ),
      ],
    }
  },
  props: {
    visible: Boolean,
  },
  computed: {
    email() {
      return this.$store.state.user.username
    },
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  methods: {
    submit() {
      if (this.matchingEmails()) {
        this.loading = true
        this.$store.dispatch('user/updateUsername', this.form).then(
                (data: ResponseType) => {
                  this.$emit('close')
                  this.loading = false
                  this.error = false
                  this.message = ''
                },
                (error: ResponseType) => {
                  this.loading = false
                  this.error = true
                  if (error.response.status == 409) {
                    this.message = this.$t('page.user.security.email.form.error.emailAlreadyExists')
                  } else {
                    this.message = this.$t('page.user.security.email.form.error.exception')
                  }
                }
        )
      }
    },
    matchingEmails(): boolean {
      if (
              this.form.confirmNewUsername !== this.form.newUsername
      ) {
        this.error = true
        this.message = this.$t('page.user.security.email.form.error.match')
        return false
      } else {
        this.error = false
        this.message = ''
        return true
      }
    },
  },
  watch: {
    visible: function(val) {
      if(val) {
        this.form.currentUsername = '';
        this.form.newUsername = '';
        this.form.confirmNewUsername = '';
      }
    }
  }
})
