
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import UpdateProfileForm from '@/components/Account/UpdateProfileForm.vue'
import UpdateProfilePictureForm from '@/components/Account/UpdateProfilePictureForm.vue'

const __default__ = defineComponent({
  name: 'Profile',
  components: {
    UpdateProfileForm,
    UpdateProfilePictureForm
  },
  data() {
    return {
      showUpdateProfileForm: false,
      showUpdateProfilePictureForm: false,
    }
  },
  methods: {},
  computed: {
    ...mapState(['user']),
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    profilePicture() {
      if (this.$store.state.user.profilePictureUrl) {
        return this.$store.state.user.profilePictureUrl
      } else {
        return 'http://bootdey.com/img/Content/avatar/avatar1.png'
      }
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f24e0f0a": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__