import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-offers"
}
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.frontPageAds.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('components.frontPageAds.title')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frontPageAds, (frontPageAd) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              class: "link",
              to: {  path: '/job/' + frontPageAd.id }
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString(frontPageAd.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            _createElementVNode("div", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "mdi mdi-map-marker-outline" }, null, -1)),
              _createElementVNode("p", _hoisted_4, _toDisplayString(frontPageAd.city), 1)
            ])
          ]))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}