import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    class: "mr-2",
    fab: "",
    icon: "",
    color: _ctx.primaryColor,
    href: _ctx.link,
    target: "_blank"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_font_awesome_icon, {
        style: { color: 'white' },
        "aria-hidden": "true",
        icon: _ctx.icon
      }, null, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["color", "href"]))
}