import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "left" }
const _hoisted_2 = ["onMouseenter"]
const _hoisted_3 = { class: "d-flex span8 mb-4" }
const _hoisted_4 = {
  key: 0,
  class: "list-jobs-logo d-none d-md-flex"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "job-list-item" }
const _hoisted_7 = { class: "d-flex justify-space-between" }
const _hoisted_8 = { class: "fs-8" }
const _hoisted_9 = { class: "d-flex location-jobs" }
const _hoisted_10 = { class: "mb-0" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "head mb-3"
}
const _hoisted_13 = { class: "d-flex" }
const _hoisted_14 = { class: "d-flex jobs-tags mr-2" }
const _hoisted_15 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_16 = {
  key: 1,
  class: "mb-0"
}
const _hoisted_17 = {
  key: 0,
  class: "d-flex jobs-tags mr-2"
}
const _hoisted_18 = { class: "mb-0" }
const _hoisted_19 = { class: "d-flex jobs-tags" }
const _hoisted_20 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", {
      class: _normalizeClass(["jobs mt-4 mt-md-0", { flex_end: _ctx.jobs.length === 0 }])
    }, [
      _createElementVNode("section", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
          return (_openBlock(), _createElementBlock("div", {
            onMouseenter: ($event: any) => (_ctx.setCurrentJobId(job.id)),
            onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCurrentJobId(null))),
            key: job.id,
            class: "list-item"
          }, [
            _createVNode(_component_router_link, {
              class: "link",
              to: { path: '/job/' + job.id }
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (job.logo && _ctx.client.properties.displayLogo)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("img", {
                          src: job.logo
                        }, null, 8, _hoisted_5)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("h2", _hoisted_8, _toDisplayString(job.title), 1),
                        _createElementVNode("div", _hoisted_9, [
                          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "mdi mdi-map-marker-outline" }, null, -1)),
                          _createElementVNode("p", _hoisted_10, _toDisplayString(job.city), 1)
                        ])
                      ]),
                      (job.recruiter)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(job.recruiter), 1))
                        : _createCommentVNode("", true),
                      (job.descriptionMission)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.truncate(_ctx.cleanText(job.descriptionMission), '...', 200)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "mdi mdi-file-document-edit-outline mr-1" }, null, -1)),
                        (job.contractType && _ctx.$te('page.job.contractType.' + _ctx.camelCase(job.contractType.toLowerCase())))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.$t('page.job.contractType.' + _ctx.camelCase(job.contractType.toLowerCase()))), 1))
                          : (job.contractType)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(job.contractType), 1))
                            : _createCommentVNode("", true)
                      ]),
                      (job.contractDurationUnit && job.contractDurationValue)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "mdi mdi-clock-outline mr-1" }, null, -1)),
                            _createElementVNode("p", _hoisted_18, _toDisplayString(job.contractDurationValue) + " " + _toDisplayString(_ctx.$tc('page.job.contractDurationUnit.' + job.contractDurationUnit.toLowerCase(), job.contractDurationValue > 1 ? 2 : 1 )), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_19, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mdi mdi-file-document-edit-outline mr-1" }, null, -1)),
                        _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('page.jobs.publishedOn')) + " " + _toDisplayString(_ctx.formatJobDate(job.creationDate)), 1)
                      ])
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ], 40, _hoisted_2))
        }), 128))
      ])
    ], 2)
  ]))
}