import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-3 pb-0" }
const _hoisted_2 = { class: "d-flex justify-content-center profile-content" }
const _hoisted_3 = { class: "p-2 div-img-account" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "p-2 d-flex flex-column justify-content-center data-profile" }
const _hoisted_6 = { class: "data-mail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_UpdateProfileForm = _resolveComponent("UpdateProfileForm")!
  const _component_UpdateProfilePictureForm = _resolveComponent("UpdateProfilePictureForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { class: "elevation-0 mb-8 rounded-lg card" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('components.profile.title')), 1),
        _createVNode(_component_v_card_text, { class: "d-flex flex-column align-items-center pt-0 mb-4" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  class: "img-account-profile rounded-circle mb-2",
                  src: _ctx.profilePicture,
                  alt: ""
                }, null, 8, _hoisted_4),
                _createVNode(_component_v_btn, {
                  size: "x-small",
                  icon: "pen",
                  class: "btn-edit-img",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUpdateProfilePictureForm = true))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "pen" })
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.user.profile.firstname) + " " + _toDisplayString(_ctx.user.profile.lastname), 1),
                _createElementVNode("span", _hoisted_6, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mdi mdi-email-outline" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.user.username), 1)
                ]),
                _createElementVNode("span", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "mdi mdi-phone-outline" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.user.profile.phoneNumber), 1)
                ])
              ])
            ]),
            _createVNode(_component_v_btn, {
              class: "btn btn-primary",
              type: "button",
              size: "small",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUpdateProfileForm = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('components.profile.buttons.updateData')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_UpdateProfileForm, {
      visible: _ctx.showUpdateProfileForm,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showUpdateProfileForm = false))
    }, null, 8, ["visible"]),
    _createVNode(_component_UpdateProfilePictureForm, {
      visible: _ctx.showUpdateProfilePictureForm,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showUpdateProfilePictureForm = false))
    }, null, 8, ["visible"])
  ], 64))
}