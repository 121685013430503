
import MultiStepFormApplyModal from '@/components/ApplyTunnel/MultiStepFormApplyModal.vue'
import { defineComponent } from 'vue'
import { truncate, cleanText, formatJobDate } from '@/utils/string'
import { mapState } from 'vuex'

const __default__ = defineComponent({
  name: 'JobsFilter',
  props: ['facets'],
  components: {
    MultiStepFormApplyModal,
  },
  data() {
    return {
      keywords: '',
      locations: [] as any,
      contractTypes: [] as any,
      categories: [] as any,
      focusedClass: false,
      page: 1,
      moreCriteria: false,
    }
  },
  watch: {
    focusedClass(value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  mounted: function () {
    this.extractAnSetParameters()
  },
  computed: {
    ...mapState(['client']),
    locationsValue: {
      get() {
        return this.locations
      },
      set(val) {
        this.locations = val
        this.$emit('locations', val)
      },
    },
    keywordsValue: {
      get() {
        return this.keywords
      },
      set(val) {
        this.keywords = val
        this.$emit('keywords', val)
      },
    },
    contractTypesValue: {
      get() {
        return this.contractTypes
      },
      set(val) {
        this.contractTypes = val
        this.$emit('contractTypes', val)
      },
    },
    categoriesValue: {
      get() {
        return this.categories
      },
      set(val) {
        this.categories = val
        this.$emit('categories', val)
      },
    },
  },
  setup() {
    return { truncate, cleanText, formatJobDate }
  },
  methods: {
    submit() {
      this.focusedClass = false;
      this.$emit('submit');
    },
    locationFilter(itemTitle, queryText, item) {
      return itemTitle.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(queryText.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1
    },
    leaveSearch() {
      this.focusedClass = false;
    },
    extractAnSetParameters() {
      const query = this.$route.query

      if (query.keywords) {
        this.keywords = query.keywords.toString()
      }

      if (query.locations) {
        if (Array.isArray(query.locations)) {
          this.locations = query.locations
        } else {
          this.locations.push(query.locations)
        }
      }

      if (this.$route.query.contractTypes) {
        if (Array.isArray(query.contractTypes)) {
          this.contractTypes = query.contractTypes
        } else {
          this.contractTypes.push(query.contractTypes)
        }
      }
      if (this.$route.query.page) {
        this.page = parseInt(this.$route.query.page.toString())
      }
    },
    clearAll() {
      this.locationsValue = [];
      this.contractTypesValue = [];
      this.categoriesValue = [];
      this.keywordsValue = "";
      this.submit();
    },
    focus() {
      this.focusedClass = true;
    },
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "074a2bb6": (_ctx.client.design.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__