import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "myModal",
  "aria-hidden": "true",
  "aria-labelledby": "exampleModalToggleLabel",
  tabindex: "-1"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered modal-xl" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  key: 0,
  class: "modal-title fs-5"
}
const _hoisted_6 = {
  key: 1,
  class: "modal-title fs-5"
}
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "mt-6 mb-3" }
const _hoisted_9 = {
  key: 0,
  class: "mt-3 mb-3"
}
const _hoisted_10 = { class: "mt-3 mb-3" }
const _hoisted_11 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_12 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_13 = { class: "text-center" }
const _hoisted_14 = {
  key: 0,
  class: "modal-rgpd"
}
const _hoisted_15 = { href: "/personal-data" }
const _hoisted_16 = { class: "modal-footer mt-4" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 2 }
const _hoisted_20 = { class: "spinner-border spinner-border-sm" }
const _hoisted_21 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.jobId == 0)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.$t('modal.apply.spontaneousTitle')), 1))
            : (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString(_ctx.$t('modal.apply.jobTitle')), 1)),
          _createElementVNode("button", {
            type: "button",
            class: "btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideModal && _ctx.hideModal(...args))),
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-xmark",
              "aria-hidden": "true",
              class: "mr-1"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                class: "form-apply",
                ref: "applyForm",
                name: "applyForm",
                onSubmit: _withModifiers(_ctx.validate, ["prevent"]),
                modelValue: _ctx.applyValidForm,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.applyValidForm) = $event)),
                "lazy-validation": ""
              }, {
                default: _withCtx(({ meta }) => [
                  _withDirectives(_createElementVNode("div", null, [
                    _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('modal.apply.form.profile.title')), 1),
                    _createVNode(_component_v_row, { class: "data-row" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  cols: "12",
                                  sm: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_select, {
                                      label: _ctx.$t('modal.apply.form.genderCode.label'),
                                      modelValue: _ctx.form.gender,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.gender) = $event)),
                                      rules: _ctx.genderCodeRule,
                                      items: _ctx.genderCodeItems,
                                      "item-title": "text",
                                      "item-value": "value",
                                      variant: "outlined"
                                    }, null, 8, ["label", "modelValue", "rules", "items"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  cols: "12",
                                  sm: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_text_field, {
                                      name: "firstname",
                                      variant: "outlined",
                                      modelValue: _ctx.form.firstname,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.firstname) = $event)),
                                      rules: _ctx.firstnameRule,
                                      label: _ctx.$t('modal.apply.form.firstname.label'),
                                      required: ""
                                    }, null, 8, ["modelValue", "rules", "label"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, {
                                  cols: "12",
                                  sm: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_text_field, {
                                      name: "lastname",
                                      variant: "outlined",
                                      modelValue: _ctx.form.lastname,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.lastname) = $event)),
                                      rules: _ctx.lastnameRule,
                                      label: _ctx.$t('modal.apply.form.lastname.label'),
                                      required: ""
                                    }, null, 8, ["modelValue", "rules", "label"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_text_field, {
                                      name: "username",
                                      variant: "outlined",
                                      modelValue: _ctx.form.username,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.username) = $event)),
                                      rules: _ctx.usernameRule,
                                      label: _ctx.$t('modal.apply.form.email.label'),
                                      required: "",
                                      "prepend-inner-icon": "mdi-email"
                                    }, null, 8, ["modelValue", "rules", "label"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  cols: "12",
                                  sm: "6"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_text_field, {
                                      name: "phoneNumber",
                                      variant: "outlined",
                                      modelValue: _ctx.form.phoneNumber,
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.phoneNumber) = $event)),
                                      rules: _ctx.phoneNumberRule,
                                      label: _ctx.$t('modal.apply.form.phonenumber.label'),
                                      required: "",
                                      "prepend-inner-icon": "mdi-phone"
                                    }, null, 8, ["modelValue", "rules", "label"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.applicationCategories.length > 0)
                      ? (_openBlock(), _createElementBlock("h3", _hoisted_9, _toDisplayString(_ctx.$t('modal.apply.form.additionalInformation.title')), 1))
                      : _createCommentVNode("", true),
                    (_ctx.applicationCategories.length > 0)
                      ? (_openBlock(), _createBlock(_component_v_autocomplete, {
                          key: 1,
                          modelValue: _ctx.form.wid,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.wid) = $event)),
                          items: _ctx.applicationCategories,
                          "item-title": "name",
                          "item-value": "wid",
                          label: _ctx.$t('modal.apply.form.applicationCategories.label'),
                          variant: "outlined"
                        }, null, 8, ["modelValue", "items", "label"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t('modal.apply.form.documents.title')), 1),
                    _createVNode(_component_v_row, { class: "row-doc" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          sm: "6"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.latestCv)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  (!_ctx.isCvInput && _ctx.displayCvSelect)
                                    ? (_openBlock(), _createBlock(_component_v_select, {
                                        key: 0,
                                        items: _ctx.cvDocuments,
                                        rules: _ctx.cvSelectRule,
                                        label: "Choose your cv",
                                        modelValue: _ctx.cvExist,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.cvExist) = $event)),
                                        onChange: _cache[8] || (_cache[8] = () => _ctx.selectCV()),
                                        "return-object": "",
                                        "item-text": "title",
                                        "item-value": "id"
                                      }, null, 8, ["items", "rules", "modelValue"]))
                                    : (_openBlock(), _createBlock(_component_v_file_input, {
                                        key: 1,
                                        rules: _ctx.cvInputRule,
                                        clearable: "",
                                        label: _ctx.$t('modal.apply.form.CV.label'),
                                        id: "inputCV",
                                        ref: "cv",
                                        variant: "outlined",
                                        onChange: _ctx.selectCV,
                                        "prepend-inner-icon": "mdi-paperclip"
                                      }, null, 8, ["rules", "label", "onChange"])),
                                  (_ctx.displayCvSelect)
                                    ? (_openBlock(), _createBlock(_component_v_btn, {
                                        key: 2,
                                        class: "ml-4",
                                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isCvInput = !_ctx.isCvInput))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(!_ctx.isCvInput ? _ctx.$t('modal.apply.form.CV.add') : _ctx.$t('modal.apply.form.CV.addExisting')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            (_ctx.latestMotivationLetter)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  (!_ctx.isMotivationLetterInput && _ctx.displayMotivationLetterSelect)
                                    ? (_openBlock(), _createBlock(_component_v_select, {
                                        key: 0,
                                        items: _ctx.motivationLetterDocuments,
                                        label: "Choose your motivation letter",
                                        modelValue: _ctx.motivationLetterExist,
                                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.motivationLetterExist) = $event)),
                                        onChange: _cache[11] || (_cache[11] = () => _ctx.selectMotivationLetter()),
                                        "return-object": "",
                                        "item-text": "title",
                                        "item-value": "id"
                                      }, null, 8, ["items", "modelValue"]))
                                    : (_openBlock(), _createBlock(_component_v_file_input, {
                                        key: 1,
                                        clearable: "",
                                        label: _ctx.$t('modal.apply.form.ML.label'),
                                        id: "motivationLetter",
                                        ref: "motivationLetter",
                                        variant: "outlined",
                                        onChange: _ctx.selectML,
                                        "prepend-inner-icon": "mdi-paperclip"
                                      }, null, 8, ["label", "onChange"])),
                                  (_ctx.displayMotivationLetterSelect)
                                    ? (_openBlock(), _createBlock(_component_v_btn, {
                                        key: 2,
                                        class: "ml-4",
                                        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isMotivationLetterInput = !_ctx.isMotivationLetterInput))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(!_ctx.isMotivationLetterInput ? _ctx.$t('modal.apply.form.ML.add') : _ctx.$t('modal.apply.form.ML.addExisting')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 512), [
                    [_vShow, _ctx.currentStep === 1]
                  ]),
                  _withDirectives(_createElementVNode("div", null, null, 512), [
                    [_vShow, _ctx.currentStep === 2]
                  ]),
                  _withDirectives(_createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('modal.apply.applyMessageTitle')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.successMsg)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ])
                  ], 512), [
                    [_vShow, _ctx.currentStep === 0]
                  ]),
                  (_ctx.currentStep === 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('modal.apply.form.RGPD.description')), 1),
                        _createElementVNode("p", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t('modal.apply.form.RGPD.description2')) + " ", 1),
                          _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.$t('modal.apply.form.RGPD.linkText')), 1)
                        ]),
                        _createVNode(_component_v_checkbox, {
                          modelValue: _ctx.consent,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.consent) = $event)),
                          label: _ctx.$t('modal.apply.form.RGPD.label'),
                          "hide-details": ""
                        }, null, 8, ["modelValue", "label"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_16, [
                    (_ctx.isFirstStep || _ctx.currentStep === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createVNode(_component_v_btn, {
                            type: "button",
                            class: "btn btn-secondary",
                            color: "secondary",
                            onClick: _ctx.hideModal,
                            disabled: _ctx.loading
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('modal.apply.form.close')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick", "disabled"])
                        ]))
                      : (_ctx.currentStep !== 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createVNode(_component_v_btn, {
                              type: "button",
                              class: "btn btn-secondary",
                              onClick: _withModifiers(_ctx.prevStep, ["prevent"]),
                              disabled: _ctx.loading
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('modal.apply.form.previous')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick", "disabled"])
                          ]))
                        : _createCommentVNode("", true),
                    (_ctx.isLastStep)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createVNode(_component_v_btn, {
                            type: "submit",
                            class: "btn btn-primary",
                            color: "primary",
                            disabled: _ctx.loading || !_ctx.consent
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("span", _hoisted_20, null, 512), [
                                [_vShow, _ctx.loading]
                              ]),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('modal.apply.form.submit')), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]))
                      : (_ctx.currentStep !== 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createVNode(_component_v_btn, {
                              type: "button",
                              class: "btn btn-primary",
                              color: "primary",
                              onClick: _withModifiers(_ctx.nextStep, ["prevent"]),
                              disabled: (meta.dirty && !meta.valid) || _ctx.loading
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('modal.apply.form.next')), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick", "disabled"])
                          ]))
                        : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["onSubmit", "modelValue"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}