
import { defineComponent } from 'vue'
import LoginForm from '../../components/Login/LoginForm.vue'
export default defineComponent({
  name: 'Login',
  components: {
    LoginForm,
  },
  data() {},
  methods: {},
  mounted() {},
})
