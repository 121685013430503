
  import { defineComponent } from 'vue'
  import Document from '@/components/Account/Document.vue'
  import Profile from '@/components/Account/Profile.vue'
  import History from '@/components/Account/History.vue'
  import Security from '@/components/Account/Security.vue'

  const __default__ = defineComponent({
    name: 'AccountView',
    components: {
      Document,
      Profile,
      History,
      Security,
    },
    data() {
      return {}
    },
    computed: {
      primaryColor() {
        return this.$store.state.client.design.primaryColor
      },
      loggedIn() {
        return this.$store.state.auth.loggedIn
      }
    },
    watch: {
      loggedIn (newLoggedIn, oldLoggedIn) {
        if (!newLoggedIn) {
          this.$router.push('/login')
        }
      }
    }
  })
  
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "24f51671": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__