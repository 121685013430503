
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
import TokenService from '../../services/token.service'

import * as yup from 'yup'
import ResponseType from '../../types/Response'
import { ValidationType, ValidationFormType } from '../../types/Validation'

export default defineComponent({
  name: 'Validation',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      newPassword: yup
        .string()
        .required(this.$t('page.token.form.password.required'))
        .min(6, this.$tc('page.token.form.password.min', 6))
        .max(40, this.$tc('page.token.form.password.max', 40)),
      confirmNewPassword: yup
        .string()
        .required(this.$t('page.token.form.confirmPassword.required'))
        .min(6, this.$tc('page.token.form.confirmPassword.min', 6))
        .max(40, this.$tc('page.token.form.confirmPassword.max', 40)),
    })
    return {
      successful: false,
      loading: false,
      message: '',
      tokenExpired: false,
      tokenMessage: '',
      schema,
    } as ValidationType
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/account')
    }
  },
  methods: {
    handleValidation(validationFormType: ValidationFormType) {
      this.message = ''
      this.successful = false
      this.loading = true
      validationFormType.userToken = this.$route.params.tokenId as string
      this.$store.dispatch('auth/validation', validationFormType).then(
        () => {
          this.successful = true
          this.loading = false
          this.$router.push('/login')
        },
        (error: any) => {
          if (error.response.data) {
            let response = error.response.data
            if (response.userTokenError) {
              this.message = this.$t(response.message)
              this.tokenExpired = true
            }
          } else {
            this.message = this.$t(error.message)
          }
          this.successful = false
          this.loading = false
        }
      )
    },
    handleResendToken() {
      this.message = ''
      this.successful = false
      this.loading = true
      let userToken = this.$route.params.tokenId as string
      this.$store.dispatch('auth/resendUserToken', userToken).then(
        (data: string) => {
          this.successful = true
          this.tokenMessage = data
        },
        (error: any) => {
          if (error.response.data) {
            let response = error.response.data
            if (response.userTokenError) {
              this.tokenMessage = this.$t(response.message)
              this.tokenExpired = true
            }
          } else {
            this.tokenMessage = this.$t(error.message)
          }
          this.successful = false
          this.loading = false
        }
      )
    },
  },
})
