import UserService from '@/services/user.service'
import ResponseType from '../../types/Response'
import { Commit } from 'vuex'
import UserInfo from '../../types/UserInfo'
import { updateUsernameData, updatePasswordData } from '../../types/UserType'
import i18n from '@/main'
import DocumentType from '@/types/DocumentType'

interface UserType {
  id: number
  username: string
  profile: {
    firstname: string
    lastname: string
    genderCode: number
    dateOfBirth: string
    streetName: string
    postalCode: string
    city: string
    phoneNumber: string
  }
  profilePicture: string
  documents: DocumentType[]
}


export const user = {
  namespaced: true,
  state: {
    id: 0,
    profile: {
      firstname: "",
      lastname: "",
      genderCode: "",
      dateOfBirth: "",
      streetName: "",
      postalCode: "",
      city: "",
      phoneNumber: "",
    },
    profilePictureUrl: '',
    documents: [],
  },
  actions: {
    updateProfile({ commit }: { commit: Commit }, profile: UserInfo) {
      return UserService.updateProfile(profile).then(
        (response: ResponseType) => {
          commit('setProfile', response)
          commit(
              'snackbar/show',
              {
                content: i18n.global.t('page.user.profile.form.success'),
                color: 'success',
              },
              { root: true }
          )
          return Promise.resolve(response)
        },
        (error: ResponseType) => {
          return Promise.reject(error)
        }
      )
    },
    updateUsername({ commit }: { commit: Commit }, data: updateUsernameData) {
      return UserService.updateUsername(data).then(
        (response) => {
          commit('setUser', response)
          commit(
              'snackbar/show',
              {
                content: i18n.global.t('page.user.security.email.form.success'),
                color: 'success',
              },
              { root: true }
          )
          return Promise.resolve(response)
        },
        (error: ResponseType) => {
          commit('updateUsernameFailure', error)
          return Promise.reject(error)
        }
      )
    },
    updatePassword({ commit }: { commit: Commit }, data: updatePasswordData) {
      return UserService.updatePassword(data).then(
        (response: ResponseType) => {
          commit(
              'snackbar/show',
              {
                content: i18n.global.t('page.user.security.password.form.success'),
                color: 'success',
              },
              { root: true }
          )
          return Promise.resolve(response)
        },
        (error: ResponseType) => {
          commit('updatePasswordFailure', error)
          return Promise.reject(error)
        }
      )
    },
    deleteUserAccount({ commit }: { commit: Commit }) {
      return UserService.deleteUserAccount().then(
        (response: ResponseType) => {
          commit('deleteAccountSuccess', response)
          return Promise.resolve(response)
        },
        (error: ResponseType) => {
          commit('deleteAccountFailure', error)
          return Promise.reject(error)
        }
      )
    },
    uploadUserCV({ commit }: { commit: Commit }, files: Blob) {
      //TODO chech the file type
      return UserService.uploadUserCV(files).then(
        (response: ResponseType) => {
          commit('setDocuments', response)
          commit(
            'snackbar/show',
            {
                content: i18n.global.t('page.user.documents.form.success'),
                color: 'success',
            },
            { root: true }
          )
          return Promise.resolve(response)
        },
        (error: ResponseType) => {
          commit('uploadCVFailure', error)
          return Promise.reject(error)
        }
      )
    },
    uploadUserML({ commit }: { commit: Commit }, files: Blob) {
      //TODO check the file type
      return UserService.uploadUserML(files).then(
        (response: ResponseType) => {
            commit('setDocuments', response)
            commit(
                'snackbar/show',
                {
                    content: i18n.global.t('page.user.documents.form.success'),
                    color: 'success',
                },
                { root: true }
            )
          return Promise.resolve(response)
        },
        (error: ResponseType) => {
          commit('uploadLettersFailure', error)
          return Promise.reject(error)
        }
      )
    },
    getFile({ commit }: { commit: Commit }, filename: string) {
      return UserService.getFile(filename).then(
        (response) => {
          commit('getFileSuccess', response)
          return Promise.resolve(response)
        },
        (error) => {
          commit('getFileFailure', error)
          return Promise.reject(error)
        }
      )
    },
    generatePresignedUrl({ commit }: { commit: Commit }, filename: string) {
      return UserService.generatePresignedUrl(filename).then(
          (response) => {
              commit('setProfilePictureUrl', response)
              return Promise.resolve(response)
          },
          (error) => {
              return Promise.reject(error)
          }
      )
    },
    deleteFile({ commit }: { commit: Commit }, filename: string) {
      return UserService.deleteFile(filename).then(
        (response) => {
            commit('setDocuments', response)
            commit(
                'snackbar/show',
                {
                    content: i18n.global.t('page.user.documents.form.deleteSuccess'),
                    color: 'success',
                },
                { root: true }
            )
            return Promise.resolve(response)
        },
        (error) => {
          commit('deleteFileSuccess', error)
          return Promise.reject(error)
        }
      )
    },
    uploadProfilePicture({ commit }: { commit: Commit }, files: Blob) {
      //TODO check the file type
      return UserService.uploadProfilePicture(files).then(
        (response: ResponseType) => {
            commit('setDocuments', response)
            commit(
                'snackbar/show',
                {
                    content: i18n.global.t('page.user.profile.picture.form.success'),
                    color: 'success',
                },
                { root: true }
            )
          return Promise.resolve(response)
        },
        (error: ResponseType) => {
          commit('uploadProfilePictureFailure', error)
          return Promise.reject(error)
        }
      )
    },
    getUserFiles({ commit }: { commit: Commit }, files: Blob) {
      //TODO check the file type
      return UserService.getUserFiles().then(
        (response: any) => {
          commit('getUserFilesSuccess', response)
          return Promise.resolve(response)
        },
        (error: any) => {
          commit('getUserFilesFailure', error)
          return Promise.reject(error)
        }
      )
    },
    getProfilePicture({ commit }: { commit: Commit }) {
      return UserService.getProfilePicture().then(
        (response: any) => {
          commit('getUserProfilePictureSuccess', response)
          return Promise.resolve(response)
        },
        (error: any) => {
          commit('getUserFilesFailure', error)
          return Promise.reject(error)
        }
      )
    },
    getDocuments({ commit }: { commit: Commit }) {
      return UserService.getDocuments().then(
        (response: any) => {
          commit('getDocumentsSuccess', response)
          return Promise.resolve(response)
        },
        (error: any) => {
          commit('getDocumentsFailure', error)
          return Promise.reject(error)
        }
      )
    },
    forgotPassword({ commit, rootState }, email: string) {
      return UserService.forgotPassword(email).then(
        (response: any) => {
          commit(
            'snackbar/show',
            {
              content: i18n.global.t('page.forgot_password.form.success'),
              color: 'success',
            },
            { root: true }
          )
          return Promise.resolve(response)
        },
        (error: any) => {
          commit(
            'snackbar/show',
            {
              content: i18n.global.t('page.forgot_password.form.error'),
              color: 'error',
            },
            { root: true }
          )
          return Promise.reject(error)
        }
      )
    },
    resetPassword({ commit }: { commit: Commit }, data: object) {
      return UserService.resetPassword(data).then(
        (response: any) => {
          commit(
            'snackbar/show',
            {
              content: i18n.global.t('page.reset_password.form.success'),
              color: 'success',
            },
            { root: true }
          )
          return Promise.resolve(response)
        },
        (error: any) => {
          commit('resetPasswordFailure', error)
          return Promise.reject(error)
        }
      )
    },
    getUser({ commit }: { commit: Commit }) {
      return UserService.getUser().then(
        (response: any) => {
          commit('setUser', response)
          commit('auth/loginSuccess', null, { root: true })
          return Promise.resolve(response)
        },
        (error: any) => {
          return Promise.reject(error)
        }
      )
    },
  },
  getters: {
    profile(state) {
      return state.profile
    },
  },
  mutations: {
    updateInfosSuccess(response: ResponseType) {},
    updateInfosFailure(error: ResponseType) {},
    uploadCVSuccess(response: ResponseType) {},
    uploadCVFailure(error: ResponseType) {},
    uploadLettersSuccess(response: ResponseType) {},
    uploadLettersFailure(error: ResponseType) {},
    updateUsernameSuccess(response: ResponseType) {},
    updateUsernameFailure(error: ResponseType) {},
    updatePasswordSuccess(response: ResponseType) {},
    updatePasswordFailure(error: ResponseType) {},
    getFileSuccess(response: ResponseType) {},
    getFileFailure(error: ResponseType) {},
    deleteFileSuccess(response: ResponseType) {},
    deleteFileFailure(error: ResponseType) {},
    uploadProfilePictureSuccess(response: ResponseType) {},
    uploadProfilePictureFailure(error: ResponseType) {},
    getUserFilesSuccess(response: any) {},
    getUserFilesFailure(error: any) {},
    getUserProfilePictureSuccess(error: any) {},
    getDocumentsSuccess(response: any) {},
    getDocumentsFailure(response: any) {},
    forgotPasswordSuccess(response: any) {},
    resetPasswordSuccess(response: any) {},
    forgotPasswordFailure(error: any) {},
    resetPasswordFailure(error: any) {},
    setProfile(state, response) {
      state.profile.firstname = response.firstname
      state.profile.lastname = response.lastname
      state.profile.genderCode = response.genderCode
      state.profile.dateOfBirth = response.dateOfBirth
      state.profile.streetName = response.streetName
      state.profile.postalCode = response.postalCode
      state.profile.city = response.city
      state.profile.phoneNumber = response.phoneNumber
    },
    setUser(state, response) {
      state.id = response.id
      state.username = response.username
      state.profile = response.profile
      state.documents = response.documents
    },
    setDocuments(state, response) {
      state.documents = response.documents
    },
    setProfilePictureUrl(state, response) {
      state.profilePictureUrl = response
    },
    clear(state) {
      state.id = ''
      state.username = ''
      state.profile.firstname = ''
      state.profile.lastname = ''
      state.profile.genderCode = ''
      state.profile.dateOfBirth = ''
      state.profile.streetName = ''
      state.profile.postalCode = ''
      state.profile.city = ''
      state.profile.phoneNumber = ''
      state.documents = []
      state.profilePicture = ''
    }
  },
  methods: {},
}
