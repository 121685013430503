
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EmbedMap',
  components: {},
  data() {},
  props: {
    jobCity: String,
    jobLanguage: String,
    googleApiKey: String,
  },
  mounted() {
    this.jobCity
    this.jobLanguage
    this.googleApiKey
  },
})
