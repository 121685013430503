
import MultiStepFormApplyModal from "@/components/ApplyTunnel/MultiStepFormApplyModal.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { truncate, cleanText, formatJobDate, camelCase } from "@/utils/string";
import JobsFilter from "@/components/Jobs/JobsFilter.vue";
import moment from "moment";
import JobsList from "@/components/Jobs/JobsList.vue";
import TarteAuCitron from "@/components/TarteAuCitron/TarteAuCitron.vue";

const __default__ = defineComponent({
    name: "JobsView",
    components: {
        JobsList,
        MultiStepFormApplyModal,
        JobsFilter,
        TarteAuCitron,
    },
    data() {
        return {
            jobs: [],
            jobsFound: 0,
            numPage: 0,
            numVisiblePage: 3,
            page: 1,
            facets: {
                contractType: [],
                categories: [],
                location: [] as any,
            },
            keywords: "",
            locations: [] as any,
            categories: [] as any,
            contractTypes: [] as any,
            dataModal: false as any,
        };
    },
    methods: {
        submit() {
            this.page = 1
            this.getJobs()
        },
        extractAnSetParameters() {
            const query = this.$route.query;

            if (query.keywords) {
                this.keywords = query.keywords.toString();
            }

            if (query.locations) {
                if (Array.isArray(query.locations)) {
                    this.locations = query.locations;
                } else {
                    this.locations.push(query.locations);
                }
            }

            if (this.$route.query.contractTypes) {
                if (Array.isArray(query.contractTypes)) {
                    this.contractTypes = query.contractTypes;
                } else {
                    this.contractTypes.push(query.contractTypes);
                }
            }

            if (this.$route.query.page) {
                this.page = parseInt(this.$route.query.page.toString());
            }
            if (query.apply === 'true') {
                this.dataModal = true
            }
        },
        getJobs() {
            const data = {
                keywords: this.keywords,
                locations: this.locations,
                contractTypes: this.contractTypes,
                categories: this.categories,
                page: this.page,
                rows: this.resultsPerPage,
            };

            let urlParams = {} as any;
            if (this.keywords) {
                urlParams.keywords = this.keywords;
            }
            if (this.locations) {
                urlParams.locations = this.locations;
            }
            if (this.contractTypes) {
                urlParams.contractTypes = this.contractTypes;
            }
            if (this.categories) {
                urlParams.categories = this.categories;
            }
            if (this.page) {
                urlParams.page = this.page;
            }

            this.$store.dispatch('jobs/getJobs', data).then((response: any) => {
                this.jobs = response.jobs
                this.jobsFound = response.numFound
                this.setNumPage(response.numFound)
                this.$router.replace({ query: urlParams })
            })
        },
        getFiltersForm() {
          this.$store.dispatch('jobs/getFiltersForm').then((response: any) => {
            this.facets.location = []
            let cities = [] as any
            let regions = [] as any
            let countries = [] as any
            response.forEach((facet) => {
              if (facet.name === 'city') {
                cities.push(
                  { header: this.$t("page.jobs.input.location.groups.city")},
                  ...facet.results,
                )
              } else if (facet.name === 'region') {
                regions.push(
                  {header: this.$t("page.jobs.input.location.groups.region")},
                  ...facet.results,
                  {divider: true}
                )
              } else if (facet.name === 'country') {
                countries.push(
                  {header: this.$t("page.jobs.input.location.groups.country")},
                  ...facet.results,
                  {divider: true}
                )
              } else {
                this.facets[facet.name] = this.buildSelect(facet)
              }
            })
            this.facets.location = this.facets.location.concat(countries);
            this.facets.location = this.facets.location.concat(regions);
            this.facets.location = this.facets.location.concat(cities);
          })
        },
        setNumPage(numFound) {
          this.numPage = Math.ceil(numFound / this.resultsPerPage)
        },
        buildSelect(facet) {
            const selectFields = [] as any
            facet.results.forEach((facetResult) => {
                const item = {title: "", value: ""}
                item.value = facetResult.value
                if (this.$te('page.job.' + facet.name + '.' + camelCase(facetResult.value.toLowerCase()))) {
                    item.title = this.$t('page.job.' + facet.name + '.' + camelCase(facetResult.value.toLowerCase())) + ' ' + '(' + facetResult.count + ')'
                } else {
                    item.title = facetResult.title
                }
                selectFields.push(item)
            })
            return selectFields
        }
},
watch: {
    page: function () {
      this.getJobs()
    },
},
mounted: function () {
    this.extractAnSetParameters()
    this.getJobs()
    this.getFiltersForm()
},

    computed: {
        ...mapState(["client"]),
        resultsPerPage() {
            return this.$store.state.client.properties.resultsPerPage != null
                ? this.$store.state.client.properties.resultsPerPage
                : 10;
        }
    },
    setup() {
        return { truncate, cleanText, formatJobDate };
    },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1026a06a": (_ctx.client.design.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__