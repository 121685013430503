
import { defineComponent } from 'vue'
import UpdateDocumentsForm from '@/components/Account/UpdateDocumentsForm.vue'


const __default__ = defineComponent({
  name: 'Documents',
  components: {
    UpdateDocumentsForm,
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    cvDocuments: function (): Array<object> {
      return this.$store.state.user.documents?.filter(function (doc: any) {
        return doc.documentType === 'CV'
      })
    },
    motivationLetterDocuments: function (): Array<object> {
      return this.$store.state.user.documents?.filter(function (doc: any) {
        return doc.documentType === 'ML'
      })
    },
  },
  data() {
    return {
      showUpdateDocumentsForm: false,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0e644617": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__