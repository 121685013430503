
import Map from '../../components/Map/Map.vue'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import JobsFilter from '@/components/Jobs/JobsFilter.vue'
import JobsList from '@/components/Map/JobsList.vue'
import TarteAuCitron from "@/components/TarteAuCitron/TarteAuCitron.vue";
import MultiStepFormApplyModal from "@/components/ApplyTunnel/MultiStepFormApplyModal.vue";
import { camelCase } from "@/utils/string";

const __default__ = defineComponent({
  name: 'JobsMapView',
  components: {
    JobsFilter,
    Map,
    JobsList,
    TarteAuCitron,
    MultiStepFormApplyModal
  },
  data() {
    return {
      jobs: [],
      currentJobId: '',
      facets: {
        contractType: [],
        location: [] as any
      },
      keywords: '',
      locations: [] as any,
      rows: 10000,
      page: 1,
      contractTypes: [] as any,
      categories: [] as any,
      gpsCoordinates: {
        southWestLat: '',
        southWestLng: '',
        northEastLat: '',
        northEastLng: '',
      },
      isBoundFit: true,
      focusedClass: false,
      moreCriteria: false,
      dataModal: false as any,
    }
  },
  watch: {
    focusedClass(value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  methods: {
    submit() {
      this.focusedClass = false;
      this.$emit('submit');
    },
    locationFilter(itemTitle, queryText, item) {
      return itemTitle.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(queryText.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1
    },
    getJobs(isForm) {
      this.focusedClass = false;
      const data = {
        keywords: this.keywords,
        locations: this.locations,
        contractTypes: this.contractTypes,
        categories: this.categories,
        page: this.page,
        rows: this.rows,
        ...this.gpsCoordinates,
      }
      if (isForm) {
        data.southWestLat = '';
        data.southWestLng = '';
        data.northEastLat = '';
        data.northEastLng = '';
      }

      let urlParams = {} as any;
      if (this.keywords) {
        urlParams.keywords = this.keywords;
      }
      if (this.locations) {
        urlParams.locations = this.locations;
      }
      if (this.contractTypes) {
        urlParams.contractTypes = this.contractTypes;
      }
      if (this.categories) {
        urlParams.categories = this.categories;
      }
      this.$store.dispatch('jobs/getJobs', data).then((response: any) => {
        this.jobs = response.jobs
        this.$router.replace({ query: urlParams })
        if(isForm) {
          this.isBoundFit = true
        }
      })
    },
    leaveSearch() {
      this.focusedClass = false;
    },
    extractAnSetParameters() {
      const query = this.$route.query;

      if (query.keywords) {
        this.keywords = query.keywords.toString();
      }

      if (query.locations) {
        if (Array.isArray(query.locations)) {
          this.locations = query.locations;
        } else {
          this.locations.push(query.locations);
        }
      }

      if (this.$route.query.contractTypes) {
        if (Array.isArray(query.contractTypes)) {
          this.contractTypes = query.contractTypes;
        } else {
          this.contractTypes.push(query.contractTypes);
        }
      }

      if (this.$route.query.categories) {
        if (Array.isArray(query.categories)) {
          this.categories = query.categories;
        } else {
          this.categories.push(query.categories);
        }
      }

      if (this.$route.query.page) {
        this.page = parseInt(this.$route.query.page.toString());
      }
      if (query.apply === 'true') {
        this.dataModal = true
      }
    },
    getFiltersForm() {
      this.$store.dispatch('jobs/getFiltersForm').then((response: any) => {
        this.facets.location = []
        let cities = [] as any
        let regions = [] as any
        let countries = [] as any
        response.forEach((facet) => {
          if (facet.name === 'city') {
            cities.push(
              { header: this.$t("page.jobs.input.location.groups.city")},
               ...facet.results,
            )
          } else if (facet.name === 'region') {
            regions.push(
              {header: this.$t("page.jobs.input.location.groups.region")},
               ...facet.results,
              {divider: true}
            )
          } else if (facet.name === 'country') {
              countries.push(
                {header: this.$t("page.jobs.input.location.groups.country")},
                ...facet.results,
                {divider: true}
              )
          } else {
            this.facets[facet.name] = this.buildSelect(facet)
          }
        })
        this.facets.location = this.facets.location.concat(countries);
        this.facets.location = this.facets.location.concat(regions);
        this.facets.location = this.facets.location.concat(cities);
      })
    },
    buildSelect(facet) {
      const selectFields = [] as any
      facet.results.forEach((facetResult) => {
        const item = {title: "", value: ""}
        item.value = facetResult.value
        if (this.$te('page.job.' + facet.name + '.' + camelCase(facetResult.value.toLowerCase()))) {
          item.title = this.$t('page.job.' + facet.name + '.' + camelCase(facetResult.value.toLowerCase())) + ' ' + '(' + facetResult.count + ')'
        } else {
          item.title = facetResult.title
        }
        selectFields.push(item)
      })
      return selectFields
    },
    setCoordinates: function (coords) {
      this.gpsCoordinates = coords
      this.getJobs(false)
    },
    setCurrentJobId: function (currentJobId) {
      this.currentJobId = currentJobId
    },
    updateParent: function (coords) {
      this.gpsCoordinates = coords
    },
    focus() {
      this.focusedClass = true;
    },
    clearAll() {
      this.locations = [];
      this.contractTypes = [];
      this.categories = [];
      this.keywords = "";
      this.gpsCoordinates = {
        southWestLat: '',
        southWestLng: '',
        northEastLat: '',
        northEastLng: '',
      };
      this.getJobs(true);
    },
  },
  mounted: function () {
    this.extractAnSetParameters()
    this.getJobs(false)
    this.getFiltersForm()
  },
  computed: {
    ...mapState(['client']),
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "55c019a6": (_ctx.client.design.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__