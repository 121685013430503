<template>
  <div class="map" id="map"></div>
</template>

<script>
import { extractGpsCoordinates } from '@/utils/string'
import { Loader } from 'google-maps'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { mapState } from 'vuex'
import { formatJobDate } from '@/utils/string'
import { faLocationDot, faCircleUser } from "@fortawesome/free-solid-svg-icons";

export default {
  name: 'Map',
  components: {},
  props: ['jobs', 'currentJobId', 'fitBounds'],
  setup() {
    return { formatJobDate }
  },
  mounted: async function () {
    const loader = new Loader(
      this.$store.state.client.properties.googleApiKey,
      {}
    )
    const google = await loader.load()
    this.google = google
    this.markerCluster = {}
    this.svg = `
        <svg fill="color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
          <circle cx="120" cy="120" opacity="1" r="70" />
          <circle cx="120" cy="120" opacity=".3" r="90" />
          <circle cx="120" cy="120" opacity=".2" r="110" />
        </svg>`
    this.initMap()
  },
  data() {
    return {
      apiKey: '',
      markers: [],
      map: {},
      google: null,
      gpsCoordinates: {
        southWestLat: 0,
        southWestLng: 0,
        northEastLat: 0,
        northEastLng: 0,
      },
      currentMarkerHover: MarkerClusterer
    }
  },
  watch: {
    jobs: function (jobs) {
      this.setMarkers(jobs)
    },

    currentJobId: function () {
      if (this.currentJobId && this.markerCluster) {
        this.markerCluster.clusters.forEach((subClusters) => {
          if (subClusters.markers.length > 1) {
            subClusters.markers.forEach((marker) => {
              if (marker.id === this.currentJobId) {
                this.currentMarkerHover = subClusters
                let icon = subClusters.marker.getIcon()
                icon.url = 'data:image/svg+xml;base64,' +
                        window.btoa(
                                this.svg.replace('color', this.client.design.primaryColor)
                        )
                subClusters.marker.setIcon(icon)
              }
            })
          } else {
            if (subClusters.marker.id === this.currentJobId) {
              this.currentMarkerHover = {
                type: 'marker',
                marker: subClusters.marker,
              }
              subClusters.marker.setIcon(
                this.getMarkerIcon(this.client.design.primaryColor)
              )
            }
          }
        })
      }
      if (this.currentJobId === null) {
        if (this.currentMarkerHover.markers && this.currentMarkerHover.markers.length > 1) {
          let icon = this.currentMarkerHover.marker.getIcon()
          icon.url = 'data:image/svg+xml;base64,' +
                  window.btoa(
                          this.svg.replace('color', '#3A4140')
                  )
          this.currentMarkerHover.marker.setIcon(icon)
        } else {
          this.currentMarkerHover.marker.setIcon(
                  this.getMarkerIcon('#3A4140')
          )
        }
      }
    },
  },
  computed: {
    ...mapState(['client']),
  },
  methods: {
    initMap: function () {
      let mapOptions = {
        zoom: 5,
        center: new google.maps.LatLng(46.603354, 1.8883335),
        mapId: '3541bba20d90dbe0',
      }
      this.map = new google.maps.Map(document.getElementById('map'), mapOptions)
      let map = this.map

      const renderer = {
                render: ({ count, position }) =>

                        new google.maps.Marker({
                          label: { text: String(count), color: "white", fontSize: "10px" },
                          icon: {
                            url: 'data:image/svg+xml;base64,' +
                                    window.btoa(
                                            this.svg.replace('color', '#3A4140')
                                    )
                            ,
                            scaledSize: new google.maps.Size(45, 45),
                          },
                          position,
                          // adjust zIndex to be above other markers
                          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                        })
      };

      this.markerCluster = new MarkerClusterer({
        map,
        onClusterClick: this.getMarkersInCluster,
        renderer
      })
      this.addScrollEvent(this.map)
    },
    getInfoWindowContent: function(job) {
      return `<section class="carousel2 d-flex flex-column justifiy-content-center">
                <div class="carousel2-inner">
                    <li class="carousel2-item d-flex flex-column justifiy-content-center">
                      <h2 class="job-title mt-2">${job.title}</h2>
                      <span class="job-subtitle mb-3">${
                              job.contractType
                      }</span>
                                      <span class="job-description mb-1">${job.city} - ${
                              job.country
                      }</span>
                                      <span class="job-created-at mb-5">${formatJobDate(
                              job.creationDate
                      )}</span>
                         <a
                        class="btn carousel2-btn btn-primary"
                        href=/job/${job.id}
                        target="_blank"
                        >
                            Voir l'offre
                        </a>
                    </li>
                    </div>
                </section>

                  `
    },
    getMarkerIcon(color) {
      return {
        path: faLocationDot.icon[4],
        fillColor: color,
        fillOpacity: 1,
        anchor: new google.maps.Point(
          faLocationDot.icon[0] / 2,
          faLocationDot.icon[1]
        ),
        // strokeWeight: 1,
        scale: 0.05,
      }
    },
    getMarkersInCluster: function (event, cluster, map) {
      let infoWin = new this.google.maps.InfoWindow({
        maxWidth: 400,
      })
      // map.fitBounds(cluster.bounds)
      if (map.getZoom() >= 8) {
        let markers = cluster['markers']

        let carouselBody = `
            <section class="carousel2 d-flex flex-column justifiy-content-center">
              <div class="markers-number text-center">${markers.length} jobs</div>
              <div class="carousel2-inner">
              ${markers
                .map((marker) => {
                  let job = marker.content
                  return `
                    <li class="carousel2-item d-flex flex-column justifiy-content-center">
                      <h2 class="job-title mt-2">${job.title}</h2>
                      <span class="job-subtitle mb-3">${
                        job.contractType
                      }</span>
                      <span class="job-description mb-1">${job.city} - ${
                    job.country
                  }</span>
                      <span class="job-created-at mb-5">${formatJobDate(
                        job.creationDate
                      )}</span>
                       <a
                        class="btn carousel2-btn btn-primary"
                        href=/job/${job.id}
                        target="_blank"
                        >
                            Voir l'offre
                        </a>
                    </li>
                  `
                })
                .join('')}
              </div>
              <button class="carousel-prev"><</button>
              <button class="carousel-next">></button>
            </section>
        `
        let nextBtn = null
        let prevBtn = null
        let carouselInner = null
        let scrollPosition = 0
        const carouselLength = markers.length

        function prevJob() {
          if (scrollPosition === 0 ) {
            scrollPosition = carouselInner.offsetWidth * (carouselLength - 1)
          } else {
            scrollPosition -= carouselInner.offsetWidth
          }
          carouselInner.scrollTo({
            top: 0,
            left: scrollPosition,
            behavior: 'smooth',
          })
        }

        function nextJob() {
          if (carouselInner.offsetWidth * (carouselLength - 1) === scrollPosition) {
            scrollPosition = 0
          } else {
            scrollPosition += carouselInner.offsetWidth
          }
          carouselInner.scrollTo({
            top: 0,
            left: scrollPosition,
            behavior: 'smooth',
          })
        }

        infoWin.setContent(carouselBody)
        infoWin.setPosition({
          lat: cluster._position.lat(),
          lng: cluster._position.lng(),
        })
        infoWin.setOptions({ pixelOffset: new google.maps.Size(0, -50) })
        infoWin.open(map)
        setTimeout(() => {
          carouselInner = document.querySelector('.carousel2-inner')
          prevBtn = document
            .querySelector('.carousel-prev')
            .addEventListener('click', prevJob)
          nextBtn = document
            .querySelector('.carousel-next')
            .addEventListener('click', nextJob)
        }, 1)
      } else {
        map.setCenter({
          lat: cluster._position.lat(),
          lng: cluster._position.lng(),
        })
        map.setZoom(map.getZoom() + 3)
      }
    },
    setMarkers: function (jobs) {
      if (this.google !== null) {
        let markers = []
        this.markerCluster.clearMarkers()
        let infoWin = new this.google.maps.InfoWindow({
          maxWidth: 400,
        })
        if (jobs && jobs.length > 0) {
          for (let i = 0; i < jobs.length; i++) {
            let job = jobs[i]
            let position = new this.google.maps.LatLng(
              extractGpsCoordinates(job.gpsCoordinates).lat,
              extractGpsCoordinates(job.gpsCoordinates).lng
            )
            let marker = new this.google.maps.Marker({
              position,
              title: job.title,
              icon: this.getMarkerIcon('#3A4140'),
              map: this.map,
              id: job.id,
              anchor: new this.google.maps.Point(0, 20),
            })
            marker.set('content', job)
            const jobContent = this.getInfoWindowContent(job)
            this.google.maps.event.addListener(marker, 'click', function () {
              infoWin.setContent(jobContent)
              infoWin.open(this.map, marker)
            })
            markers.push(marker)
          }
          this.markerCluster.addMarkers(markers)
          if (this.fitBounds) {
            this.fitBoundsToCluster();
            this.$emit('update-fit-bound')
            if(this.map.getZoom() >= 6){
              this.map.setZoom(6)
            }
          }
        }
      }
    },
    addScrollEvent: function (map) {
      let self = this
      google.maps.event.addListener(map, 'idle', function () {
        let coordinatesData = {
          southWestLat: map.getBounds().getSouthWest().lat(),
          southWestLng: map.getBounds().getSouthWest().lng(),
          northEastLat: map.getBounds().getNorthEast().lat(),
          northEastLng: map.getBounds().getNorthEast().lng(),
        }
        self.$emit('updateMap', coordinatesData)
      })
    },
    fitBoundsToCluster() {
      const bounds = new google.maps.LatLngBounds();
      this.jobs.forEach(job => {
        bounds.extend(
          extractGpsCoordinates(job.gpsCoordinates)
        );
      });
      this.map.fitBounds(bounds);
    }
  },
}
</script>
<style lang="scss">
.btn-primary {
  background-color: v-bind('client.design.primaryColor') !important;
  border-color: v-bind('client.design.primaryColor') !important;
  border-radius: 5px;
  text-transform: inherit;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px !important;
}
.map {
  width: 50%;
  height: 100%;
  margin: 0;
}
.carousel2 {
  width: 360px;
  height: fit-content;
  margin: 8px;
  position: relative;
  overflow: hidden;
  padding-right: 0px;
  padding-bottom: 0px;
  max-width: 270px;
  max-height: 1204px;
  min-width: 0px;
  &-inner {
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
  }
  &-item {
    width: 100%;
    height: 100%;
    flex: 1 0 100%;
    h3 {
      margin-top: 10px;
    }
    p {
      margin-top: 5px;
    }
  }
  &-btn{
    width: fit-content;
    margin: auto;
    border-radius: 5px !important;
    font-weight: 500 !important;
    &:hover{
      color: white;
    }
    margin-bottom: 2px;
    box-shadow: 0px 3px 1px -2px  rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .markers-number {
    color: v-bind('client.design.primaryColor');
    font-weight: 600;
  }
  h2.job-title  {
    font-size: 22px;
  }
  .job-title {
    font-weight: 500;
    text-align: center;
  }
  .job-subtitle {
    font-weight: 300;
    text-align: center;
    font-size: 16px;
  }
  .job-title:after {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background: v-bind('client.design.primaryColor');
  }
  .job-description {
    font-weight: 300;
    font-size: 15px;
    text-align: center;
  }
  .job-created-at {
    font-size: 14px;
    color: #939798;
    text-align: center;
  }
}
.carousel-prev,
.carousel-next {
  position: absolute;
  bottom: 8px;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  font-size: 24px;
  color: v-bind('client.design.primaryColor');
  font-weight: 500;
}
.carousel-prev {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.carousel-next {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
