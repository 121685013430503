
import { defineComponent } from 'vue'
import { returnYearDate, returnMonthDate, returnDayDate, formatJobDate } from "@/utils/string";
import {ApplicationType} from "@/types/ApplicationType";

const __default__ = defineComponent({
  name: 'History',
  components: {},
  data() {
    return {
      applications: [] as ApplicationType[],
    }
  },
  computed: {
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    logo() {
      return this.$store.state.client.design.logo
    },
  },
  methods: {
    getApplications() {
      this.$store.dispatch('apply/getApplications').then((response: any) => {
        this.applications = response.applications
      })
    },
  },
  mounted() {
    this.getApplications()
  },
  setup() {
    return { returnMonthDate, returnDayDate, returnYearDate, formatJobDate }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0504fbc4": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__