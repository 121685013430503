
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import ResponseType from '../../types/Response'

const __default__ = defineComponent({
  name: 'Register',
  components: {},
  data() {
    return {
      valid: true,
      username: '',
      usernameRule: [
        (v) => !!v || this.$t('page.signup.form.username.required'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('page.signup.form.username.invalid'),
        (v) =>
          (v && v.length <= 50) ||
          this.$tc('page.signup.form.username.max', 50),
      ],
      password: '',
      passwordRule: [
        (v) => !!v || this.$t('page.signup.form.password.required'),
        (v) =>
          (v && v.length <= 40) ||
          this.$tc('page.signup.form.password.max', 40),
        (v) =>
          (v && v.length >= 6) || this.$tc('page.signup.form.password.min', 6),
      ],
      firstname: '',
      firstnameRule: [
        (v) => !!v || this.$t('page.signup.form.firstname.required'),
        (v) =>
          (v && v.length <= 20) ||
          this.$tc('page.signup.form.firstname.max', 20),
        (v) =>
          (v && v.length >= 3) || this.$tc('page.signup.form.firstname.min', 3),
      ],
      lastname: '',
      lastnameRule: [
        (v) => !!v || this.$t('page.signup.form.lastname.required'),
        (v) =>
          (v && v.length <= 20) ||
          this.$tc('page.signup.form.lastname.max', 20),
        (v) =>
          (v && v.length >= 3) || this.$tc('page.signup.form.lastname.min', 3),
      ],
      successful: false,
      loading: false,
      message: '',
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn
    },
    ...mapState(['client']),
    currentUrl() {
      return window.location.href
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/account')
    }
  },
  methods: {
    async validate() {
      const form = this.$refs.loginForm as any
      if (form.validate()) this.handleRegister()
    },
    handleRegister() {
      this.message = ''
      this.successful = false
      this.loading = true
      let registerData = {
        email: this.username,
        password: this.password,
        firstname: this.firstname,
        lastname: this.lastname,
      }
      this.$store.dispatch('auth/register', registerData).then(
        (data: ResponseType) => {
          this.successful = true
          this.loading = false
          this.$router.push('/login')
        },
        (error: ResponseType) => {
          if (error.response.status == 409) {
            this.message = this.$t('page.signup.form.errors.emailAlreadyExists')
          } else {
            this.message = this.$t('page.signup.form.errorMessage')
          }
          this.successful = false
          this.loading = false
        }
      )
    },
    resetForm() {
      let form = this.$refs.registerForm as any
      form.reset()
      form.resetValidation()
    },
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "edaca156": (_ctx.client.design.primaryColor),
  "4ef51408": (_ctx.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__