
import { defineComponent } from 'vue'
import 'bootstrap/dist/js/bootstrap.min.js'
import Header from './components/Header/Header.vue'
import Snackbar from '@/components/Snackbar.vue'
import NotFound from "@/components/Error/NotFound.vue";
import Footer from "@/components/Footer/Footer.vue";
import DocumentType from "@/types/DocumentType";

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Snackbar,
    NotFound,
    Footer
  },
  data() {
    return {}
  },
  methods: {
    setFavicon() {
      if (this.favicon != null) {
        const favicon = document.getElementById(
                'favicon'
        ) as HTMLAnchorElement | null
        if (favicon != null) {
          favicon.href = this.favicon
        }
      }
    },
    setTitle() {
      const title = document.getElementById(
              'title'
      ) as HTMLAnchorElement | null
      if (title != null) {
        title.innerHTML = this.clientCompleteName + " - " + "Nos offres d'emploi"
      }
    },
    getUser() {
      if (localStorage.getItem('token')) {
        this.$store.dispatch('user/getUser').then(
                () => {
                  this.getProfilePicture()
                }
        )
      }
    },
    getProfilePicture() {
      const profilePictureDocuments = this.getProfilePictureDocumentFromState()
      if (profilePictureDocuments.length > 0) {
        this.$store.dispatch('user/generatePresignedUrl', profilePictureDocuments[0].path)
      }
    },
    getProfilePictureDocumentFromState: function (): Array<DocumentType> {
      return this.$store.state.user.documents?.filter(function (document: DocumentType) {
        return document.documentType === 'PP'
      })
    },
  },
  mounted() {
    this.getUser()
    this.setFavicon()
    this.setTitle()
  },
  computed: {
    favicon(): string {
      return this.$store.state.client.design.favicon
    },
    clientId(): string {
      return this.$store.state.client.id
    },
    clientCompleteName(): string {
      return this.$store.state.client.completeName
    }
  },
})
