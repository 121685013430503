
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'LegalMentions',
  data() {
    return {
      legalMentions: '',
    }
  },
  methods: {
    getClientLegalMentions() {
      this.$store
        .dispatch('client/getClientLegalMentions')
        .then((response: any) => {
          this.legalMentions = response['legalMentions']
        })
    },
  },
  mounted() {
    this.getClientLegalMentions()
  },
  computed: {
    ...mapState(['client']),
  },
})
