import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-3 pb-0" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger mt-3",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.show) = $event)),
    "max-width": "800px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, {
            class: "d-flex justify-content-between",
            style: _normalizeStyle({ background: _ctx.primaryColor, color: 'white'})
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('page.user.security.email.form.title')), 1),
              _createVNode(_component_v_btn, {
                right: "",
                icon: "mdi-close",
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
              })
            ]),
            _: 1
          }, 8, ["style"]),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, {
                    name: "updateEmailForm",
                    ref: "updateEmailForm",
                    "lazy-validation": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                id: "currentEmail",
                                name: "currentEmail",
                                type: "email",
                                modelValue: _ctx.form.currentUsername,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.currentUsername) = $event)),
                                variant: "outlined",
                                label: _ctx.$t('page.user.security.email.form.currentEmail.label')
                              }, null, 8, ["modelValue", "label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                id: "newEmail",
                                name: "newEmail",
                                type: "email",
                                modelValue: _ctx.form.newUsername,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.newUsername) = $event)),
                                variant: "outlined",
                                rules: _ctx.newEmailRule,
                                label: _ctx.$t('page.user.security.email.form.newEmail.label')
                              }, null, 8, ["modelValue", "rules", "label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                id: "confirmNewEmail",
                                name: "confirmNewEmail",
                                type: "email",
                                modelValue: _ctx.form.confirmNewUsername,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.confirmNewUsername) = $event)),
                                variant: "outlined",
                                rules: [_ctx.confirmNewEmailRule, _ctx.matchingEmail],
                                label: _ctx.$t('page.user.security.email.form.confirmEmail.label')
                              }, null, 8, ["modelValue", "rules", "label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.error)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                class: "mb-5",
                color: _ctx.primaryColor,
                variant: "outlined",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.show = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('page.user.security.email.form.close')), 1)
                ]),
                _: 1
              }, 8, ["color"]),
              _createVNode(_component_v_btn, {
                type: "submit",
                class: "mr-4 mb-5 btn btn-primary",
                style: _normalizeStyle({ 'background-color': _ctx.primaryColor + '!important', color: 'white'}),
                onClick: _ctx.submit,
                disabled: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('page.user.security.email.form.submit')), 1)
                ]),
                _: 1
              }, 8, ["style", "onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}