import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from './plugins/font-awesome'
import '@fortawesome/vue-fontawesome'
import setupInterceptors from './services/setupInterceptors'
import axiosInstance from './services/api'
import TokenService from './services/token.service'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import { fa } from 'vuetify/iconsets/fa'
import { mdi, aliases } from 'vuetify/iconsets/mdi'
import { createGtm } from 'vue-gtm'
import moment from 'moment'
import {
  loadLocaleMessages,
  setI18nLanguage,
  setupI18n,
  SUPPORT_LOCALES,
} from './services/i18n'
import Jobs from "@/views/Jobs/Jobs.vue";
import JobsMap from "@/views/Jobs/JobsMap.vue";

const i18n = setupI18n({
  fallbackLocale: 'fr',
  availableLocales: ['fr', 'en', 'es', 'it'],
})
const head = createHead()

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
})

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    'JobsView',
    'JobView',
    'NotFoundView',
    'LegalMentionsView',
    'PersonalDataView',
    'JobsMapView',
  ]
  const authPages = [
    'LoginView',
    'RegisterView',
    'ValidationView',
    'ForgotPasswordView',
    'ResetPasswordView',
  ]

  const pageName = to.name as string;
  const authRequired = !publicPages.includes(pageName) && !authPages.includes(pageName)
  const enableLoginRegister = store.getters.getClient.state.properties.enableLoginRegister
  const loggedIn = localStorage.getItem('token')

  if (!enableLoginRegister && authPages.includes(pageName)) {
    next("/jobs")
  }

  // const paramsLocale = to.params.locale;
  let locale = navigator.language.substring(2, 0)

  if (!SUPPORT_LOCALES.includes(locale)) {
    locale = 'en'
  }

  if (!i18n.global.availableLocales.includes(locale)) {
    await loadLocaleMessages(i18n, locale)
  }

  // set i18n language
  setI18nLanguage(i18n, locale)
  moment.locale(locale)

  if (loggedIn) {
    // call refresh token when navigate on front to see session state
    // call arrive in setupInterceptor and logout if refreshToken expired or 403 (Forbiden) response.
    store.dispatch('auth/refreshToken', {
      refreshToken: TokenService.getLocalRefreshToken(),
    }).then((response: any) => {
      TokenService.setRefreshToken(response.refreshToken)
      TokenService.setToken(response.accessToken)
    })
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

store.dispatch('client/getClientInformation')
  .catch((err) => {})
  .finally(() => {
    let app = createApp(App)
    app.provide('$store', store)

    const gtmId = store.getters.getClient.state.properties.gtmId
    if (gtmId) {
      app.use(createGtm({
        id: gtmId,
        vueRouter: router,
        ignoredViews: [
          "LoginView",
          "RegisterView",
          "ValidationView",
          "AccountView.vue",
          "NotFoundView",
          "HistoryView",
          "LegalMentionsView",
          "ResetPasswordView",
          "PersonalDataView",
        ]
      }))
    }

    const map = store.getters.getClient.state.properties.map

    if (map) {
      router.addRoute({ path: '/jobs', name: 'JobsMapView', component: JobsMap })
    } else {
      router.addRoute({ path: '/jobs', name: 'JobsView', component: Jobs })
    }

    app.use(router)
    app.use(store)
    app.use(i18n)
    app.use(vuetify)
    app.use(head)
    app.component('font-awesome-icon', FontAwesomeIcon)
    setupInterceptors(store)
    app.mount('#app')
  })


export default i18n
