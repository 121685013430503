
import { defineComponent } from 'vue'

interface InputFileEvent extends Event {
  target: HTMLInputElement
}

export default defineComponent({
  name: 'UpdateDocumentsForm',
  data() {
    return {
      loadingCV: false,
      loadingML: false,
      error: false,
      message: '',
    }
  },
  props: {
    visible: Boolean,
  },
  computed: {
    cvDocuments: function (): Array<object> {
      return this.$store.state.user.documents?.filter(function (doc: any) {
        return doc.documentType === 'CV'
      })
    },
    motivationLetterDocuments: function (): Array<object> {
      return this.$store.state.user.documents?.filter(function (doc: any) {
        return doc.documentType === 'ML'
      })
    },
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  methods: {
    uploadCV(event: InputFileEvent) {
      this.loadingCV = true
      this.message = ''

      let formData = new FormData()
      if (event.target.files !== null && event.target.files.length > 0) {
        formData.append('file', event.target.files[0])
      }

      this.$store
              .dispatch('user/uploadUserCV', formData)
              .then((data: Array<DocumentType>) => {
                        this.message = ''
                        this.loadingCV = false
                      },
                      (error: ResponseType) => {
                        this.message = ''
                        this.loadingCV = false
                      }
              )
    },
    uploadML(event: InputFileEvent) {
      this.loadingML = true
      this.message = ''

      let formData = new FormData()
      if (event.target.files !== null && event.target.files.length > 0) {
        formData.append('file', event.target.files[0])
      }

      this.$store
              .dispatch('user/uploadUserML', formData)
              .then((data: Array<DocumentType>) => {
                  this.message = ''
                  this.loadingML = false
                },
                (error: ResponseType) => {
                  this.message = ''
                  this.loadingML = false
                }
              )
    },
    getDocument(document: any) {
      this.$store.dispatch('user/getFile', document.path).then((response: any) => {
        var pdf = new Blob([response], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(pdf)
        window.open(fileURL, '_blank')
      })
    },
    deleteDocument(document: any) {
      if (confirm('Are you sure you want to delete ' + document.name + ' ?')) {
        this.$store
                .dispatch('user/deleteFile', document.path)
                .then((response: any) => {
                  if (response.status === 'Success') {
                    this.$store.commit(
                            'user/setFiles',
                            response.updatedTalentProfile.documents
                    )
                    this.message = response.message
                  } else {
                    this.message = response.message
                  }
                })
      }
    }
  }
})
