import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "h-100 responsive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_Snackbar = _resolveComponent("Snackbar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (!_ctx.clientId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_NotFound)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Snackbar),
            _createVNode(_component_Header),
            _createVNode(_component_v_main, { class: "h-100 responsive main-div" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, {
                  fluid: "",
                  class: "p-0 h-100 responsive"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_view)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_Footer)
          ]))
    ]),
    _: 1
  }))
}