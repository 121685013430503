<template>
  <v-app-bar app :elevation="0" class="header-underlined">
    <v-app-bar-title>
      <router-link to="/jobs" class="no-decoration-link" tag="span"
        ><img class="navbar-logo"
          :src=logo
      /></router-link>
    </v-app-bar-title>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-flex d-sm-none mr-5"></v-app-bar-nav-icon>
    <div class="d-none d-sm-flex mr-5">
      <div class="d-flex">
       <div class="d-flex" v-if="loggedIn">
          <v-btn v-bind="props" class="btn-profile text-capitalize">
            <router-link class="link profile-pic-link" :to="{ path: '/account' }">
            <img v-if="user.profilePictureUrl"
             class="img-nav-profile rounded-circle"
             :src="user.profilePictureUrl"
             alt=""
           />
            <span v-else class="profile-pic-letter">{{ getAndCapitalizeFirstLetter(user.profile.firstname) }}{{ getAndCapitalizeFirstLetter(user.profile.lastname) }}</span>
            </router-link>
          </v-btn>
          <v-btn href="#" @click.prevent="logOut" style="cursor: pointer" class="no-decoration-link text-capitalize btn-logout">
            <font-awesome-icon icon="fa-arrow-right-from-bracket" aria-hidden="true" class="mr-1" />
          </v-btn>
        </div>
        <div v-else>
          <v-btn v-for="item in menuItems" :key="$t(item.title)" :to="item.path" :class="item.class" :href="item.href">
            <font-awesome-icon :icon="item.icon" class="mr-2" />
            {{ $t(item.title) }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" temporary location="right">
    <v-list>
      <v-list-item>
        <v-list-item-title>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="loggedIn">
        <v-list-item-title>
          <router-link to="/account" class="no-decoration-link">{{
            $t('navbar.myAccount')
          }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-divider v-if="loggedIn"></v-divider>
      <v-list-item v-if="loggedIn">
        <v-list-item-title>
          <a href="#" @click.prevent="logOut" style="cursor: pointer" class="no-decoration-link">{{
            $t('navbar.logout')
          }}</a>
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-else v-for="item in menuItems" :key="$t(item.title)" :to="item.path" :href="item.href">
        <v-list-item-title>
          <a :href="item.href" style="cursor: pointer" class="no-decoration-link">
        <font-awesome-icon :icon="item.icon" class="mr-2" />
        {{ $t(item.title) }}
          </a>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getAndCapitalizeFirstLetter } from "@/utils/string";
export default {
  name: 'Header',
  data() {
    return {
      drawer: false,
      rawProfilePic: 'http://bootdey.com/img/Content/avatar/avatar1.png',
      menuItems: []
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout')
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    loggedIn() {
      return this.$store.state.auth.loggedIn
    },
    primaryColor() {
      return this.$store.state.client.design.primaryColor
    },
    logo() {
      return this.$store.state.client.design.logo
    },
    enableLoginRegister() {
      return this.$store.state.client.properties.enableLoginRegister
    },
    website() {
      return this.$store.state.client.website
    }
  },
  created() {
    if (this.website) {
      this.menuItems.push({ title: 'navbar.aboutUs', href: this.website, icon: null, class: 'about-us' })
    }
    if (this.enableLoginRegister) {
      this.menuItems.push({
        title: 'navbar.login',
        path: '/login',
        icon: 'sign-in-alt',
        class: 'btn btn-primary-inverse'
      })
    }
  },
  setup() {
    return { getAndCapitalizeFirstLetter }
  }
}
</script>

<style scoped lang="scss">

.about-us {
  text-transform: initial;
  color: #434347;
  font-weight: 900;
  font-size: 20px !important;
  &:hover {
    color: v-bind(primaryColor);
  }
}

.about-us.v-btn:hover :deep(.v-btn__overlay) {
  opacity: 0;
}

.no-decoration-link {
  text-decoration: none;
  color: inherit;
}
.v-btn{
  font-size: 16px;
}
.header-underlined {
  border-bottom: 3px solid v-bind(primaryColor);
}
.navbar-logo{
  padding: 5px;
}
.img-nav-profile{
  height: 35px;
  width: 35px;
  object-fit: cover;
  aspect-ratio: 1;
}
.profile-pic-letter{
  display: flex;
  background: #f8f8f8;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 35px;
  padding-left: 8px;
}
.profile-pic-link{
  text-decoration: none;
  color: #434347;
}

.btn-primary-inverse {
  background-color: white;
  border-radius: 5px;
  text-transform: inherit;
  font-weight: bold;
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid v-bind(primaryColor);
  color: v-bind(primaryColor);
  svg {
    color: v-bind(primaryColor);
  }
  &:hover {
    background-color: v-bind(primaryColor) !important;
    color: white !important;
    svg {
      color: white;
    }
  }
}

:deep(.v-btn.btn-profile) {
  .v-btn__overlay{
    opacity: 0;
    background-color: white;
  }
  .v-ripple__container{
    opacity: 0 !important;
  }
}
.btn-logout{
  padding-left: 0px;
  padding-right: 0px;
}
</style>
